import React from 'react'
import { Modal, Table } from 'react-bootstrap';

const ModalDefaultMb = ({ show, handleClose }) => {

    const deafultMb = [
        { PLC: "SIEMENS", tipo: "ANALOGICA", mb: 40005, dWells: "Pit 1", wits: 0 },
        { PLC: "SIEMENS", tipo: "ANALOGICA", mb: 40006, dWells: "Pit 2", wits: 0 },
        { PLC: "SIEMENS", tipo: "ANALOGICA", mb: 40007, dWells: "Pit 3", wits: 0 },
        { PLC: "SIEMENS", tipo: "ANALOGICA", mb: 40008, dWells: "Pit 4", wits: 0 },
        { PLC: "SIEMENS", tipo: "ANALOGICA", mb: 40009, dWells: "Pit 5", wits: 0 },
        { PLC: "SIEMENS", tipo: "ANALOGICA", mb: 40010, dWells: "Pit 6", wits: 0 },
        { PLC: "SIEMENS", tipo: "ANALOGICA", mb: 40011, dWells: "Pit 7", wits: 0 },
        { PLC: "SIEMENS", tipo: "ANALOGICA", mb: 40012, dWells: "Pit 8", wits: 0 },
        { PLC: "SIEMENS", tipo: "ANALOGICA", mb: 40013, dWells: "Pit 9", wits: 0 },
        { PLC: "SIEMENS", tipo: "ANALOGICA", mb: 40014, dWells: "Pit 10", wits: 0 },
        { PLC: "SIEMENS", tipo: "ANALOGICA", mb: 40015, dWells: "Pit 11", wits: 0 },
        { PLC: "SIEMENS", tipo: "ANALOGICA", mb: 40016, dWells: "Pit 12", wits: 0 },
        { PLC: "SIEMENS", tipo: "DIGITAL", mb: 40001, dWells: "Block Height Encoder Count", wits: 0 },
        { PLC: "SIEMENS", tipo: "DIGITAL", mb: 40003, dWells: "Sandline Encoder Count", wits: 0 },
        { PLC: "SIEMENS", tipo: "DIGITAL", mb: 40032, dWells: "Block Height Encoder Rate", wits: 0 },
        { PLC: "SIEMENS", tipo: "DIGITAL", mb: 40033, dWells: "Sandline Encoder Rate", wits: 0 },
        { PLC: "SIEMENS", tipo: "DIGITAL", mb: 40023, dWells: "Rotary count", wits: 0 },
        { PLC: "SIEMENS", tipo: "DIGITAL", mb: 40025, dWells: "Rotary rate", wits: 0 },
        { PLC: "SIEMENS", tipo: "DIGITAL", mb: 40017, dWells: "Mud Pump #1 Strokes", wits: 0 },
        { PLC: "SIEMENS", tipo: "DIGITAL", mb: 40019, dWells: "Pump #1 rate", wits: 0 },
        { PLC: "SIEMENS", tipo: "DIGITAL", mb: 40020, dWells: "Mud Pump #2 Strokes", wits: 0 },
        { PLC: "SIEMENS", tipo: "DIGITAL", mb: 40022, dWells: "Pump #2 rate", wits: 0 },
        { PLC: "SIEMENS", tipo: "DIGITAL", mb: 40026, dWells: "Mud Pump #3 Strokes", wits: 0 },
        { PLC: "SIEMENS", tipo: "DIGITAL", mb: 40028, dWells: "Pump #3 rate", wits: 0 },
        { PLC: "SIEMENS", tipo: "DIGITAL", mb: 40029, dWells: "Mud Pump #4 Strokes", wits: 0 },
        { PLC: "SIEMENS", tipo: "DIGITAL", mb: 40031, dWells: "Pump #4 rate", wits: 0 }
    ]

    return (
        <>
            <Modal show={show} onHide={handleClose} id='defaultMbModal' aria-labelledby='defaultMbModalLabel'>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Default Configuration Modbus
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table striped bordered hover size="sm" className='align-middle'>
                        <thead>
                            <tr>
                                <th>PLC</th>
                                <th>Tipo</th>
                                <th>MB</th>
                                <th>Wits</th>
                                <th>Slot D-wells</th>
                            </tr>
                        </thead>
                        <tbody>
                            {deafultMb.map((i, index) => (
                                <tr key={i.mb}>
                                    {index === 0 && <td rowSpan={26}>{i.PLC}</td>}
                                    {index === 0 && <td rowSpan={12}>{i.tipo}</td>}
                                    {index === 12 && <td rowSpan={14}>{i.tipo}</td>}
                                    <td>{i.mb}</td>
                                    <td>{i.wits}</td>
                                    <td>{i.dWells}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalDefaultMb