import React from 'react';
import { toast } from 'react-toastify';

const clearCookies = () => {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    }
};

const clearLocalStorage = () => { localStorage.clear(); };
const clearSessionStorage = () => { sessionStorage.clear(); };

const clearAllData = (e) => {
    e.preventDefault(); 
    clearCookies();
    clearLocalStorage();
    clearSessionStorage();
    toast.warning('Datos de navegación eliminados');
};

const Clear = () => {
    return (
        <button
            title='Eliminar datos de navegación'
            onClick={(e) => clearAllData(e)}
            className="btn btn-sm btn-danger fa-solid fa-eraser" />
    );
};

export default Clear;