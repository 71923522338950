import React from "react";

export const Pagination = ({
  paginaActual,
  totalPaginas,
  setPaginaActual,
  registrosPorPagina,
  setRegistrosPorPagina,
  documentosFiltrados,
  broken,
  collapsed,
  mobile,
  colors
}) => {

  return (
    <>
      {registrosPorPagina < documentosFiltrados.length
        ?
        <footer className=" text-center  fixed-bottom"
          style={{
            backgroundColor: colors?.colorBg || '#ffffff',
            marginLeft: broken || mobile ? '' : (!collapsed ? '250px' : '80px'),
            transition: 'margin-left 0.3s ease',
            zIndex: 1
          }}>

          <div className="container">
            <div className="row justify-content-center mt-3 ">

              <div className="col-auto">
                <div className="btn-group btn-group-sm mb-3 text-center d-block border-dark ">
                  <button
                    className="btn border-dark bg-light "
                    style={{ width: '30px' }}
                    disabled={paginaActual === 1}
                    title="First page"
                    onClick={() => setPaginaActual(1)}
                  >
                    &lt;&lt;
                  </button>
                  <button
                    className="btn border-dark bg-light"
                    style={{ width: '30px' }}
                    disabled={paginaActual === 1}
                    title="Prev page"
                    onClick={() => {
                      if (paginaActual > 1) {
                        setPaginaActual(pre => pre - 1);
                      }
                    }}
                  >
                    <i className="fa-solid fa-angle-left" />
                  </button>
                  <button
                    className="btn border-dark bg-light"
                    disabled
                    style={{ width: '30px' }}
                  >
                    {paginaActual}
                  </button>
                  <button
                    className="btn border-dark bg-light"
                    disabled={paginaActual === totalPaginas}
                    style={{ width: '30px' }}
                    title="Next page"
                    onClick={() => {
                      if (paginaActual < totalPaginas) {
                        setPaginaActual(post => post + 1);
                      }
                    }}
                  >
                    <i className="fa-solid fa-angle-right"></i>
                  </button>
                  <button
                    className="btn border-dark bg-light"
                    disabled={paginaActual === totalPaginas}
                    title="Last page"
                    style={{ width: '30px' }}
                    onClick={() => setPaginaActual(totalPaginas)}
                  >
                    &gt;&gt;
                  </button>
                </div>
              </div>

              <div className="col-auto">
                <div className="input-group input-group-sm mb-3">
                  <span className="input-group-text" style={{ width: '30px' }}>
                    <i className='fa-solid fa-eye'></i>
                  </span>
                  <input
                    type="number"
                    min={1}
                    style={{ maxWidth: '60px' }}
                    className="form-control"
                    value={registrosPorPagina}
                    onChange={(e) => setRegistrosPorPagina(e.target.value)}
                  />
                  <span className="input-group-text">de {documentosFiltrados.length}</span>
                </div>
              </div>

            </div>
          </div>
        </footer>

        : null
      }
    </>
  );
}
