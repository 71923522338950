import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import { variablesPath } from '../variables/Variables'
import { LoaderES } from './LoaderES';
import { comparePassword, cryptPassword } from '../config/pass';
import axios from "axios";
import logo from '../images/logo.png'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Clear from './Clear';
import { getTableByIdBd, insertTableBd, updateTableBd } from '../config/functions';
import '../css/login.css';
import 'react-toastify/dist/ReactToastify.css';

export const Login = () => {

    const [authToken, setAuthToken] = useState("")
    const [usuario, setUsuario] = useState('')
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false)

    const [pwd, setPwd] = useState('')
    const [rememberPwd, setRememberPwd] = useState(false);

    const [show, setShow] = useState(false);

    const [id, setId] = useState(0)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const setToken = (token) => {
        if (token) {
            setAuthToken(token)
            localStorage.setItem('token', JSON.stringify(token))
        } else {
            setAuthToken(null)
            localStorage.removeItem('token')
        }
    }

    const login = async (e) => {
        e.preventDefault()
        if (usuario !== '' && pwd !== '') {
            try {
                setIsLoading(true)
                const res = await axios.get(variablesPath.API_URL + 'getuser2/' + usuario)
                const lastLogin = await getTableByIdBd(usuario, 'getuserlogin')
                const temporal = res.data
                if (temporal.length === 0) {
                    toast.warning('Revise la información suministrada, no es posible inicar sesión')
                } else if (temporal[0].nb_usurio_cmbcla === '1') {
                    setId(temporal[0].nb_usurio_idn)
                    changePass(temporal[0].vr_usurio_usu)
                } else if (temporal.length > 0) {
                    //const result = bcrypt.compareSync(pwd, temporal[0].vr_usurio_cla)
                    const result = comparePassword(pwd, temporal[0].vr_usurio_cla);
                    if (result === true && temporal[0].vr_usurio_est === "a") {
                        if (lastLogin.length > 0) {
                            await updateTableBd('updateuserlogin', { vr_usurio_usu: usuario })
                        } else {
                            await insertTableBd('createuserlogin', { vr_usurio_usu: usuario })
                        }
                        toast.success('Bienvenido: ' + usuario)
                        setToken({
                            id: temporal[0].nb_usurio_idn,
                            estado: temporal[0].vr_usurio_est,
                            tipo: temporal[0].nb_rol_idn,
                            userName: temporal[0].vr_usurio_usu,
                        })
                        history.push("/menu")
                    } else {
                        setToken(null)
                        toast.warning('Usuario no autorizado')
                    }
                } else {
                    setToken(null);
                    toast.warning("Hay un error con los datos del formulario")
                }
            } catch (error) {
                toast.warning('BD don\'t work')
            } finally {
                setIsLoading(false)
            }
        } else {
            toast.warning('Ingrese Usuario y contraseña para validar')
        }
    }

    const changePass = async (user) => {
        handleShow()
        toast.warning('Debe Cambiar la contraseña: ' + user)
    }

    const savePass = () => {
        const pwd1 = document.getElementById('inputPassword').value
        const pwd2 = document.getElementById('inputPassword2').value

        if (pwd1 === pwd2) {
            //const pwd_e = encriptar(pwd1)
            const pwd_e = cryptPassword(pwd1)
            const dataSend = {
                nb_usurio_idn: id,
                nb_usurio_cmbcla: '0',//0
                vr_usurio_cla: pwd_e
            }
            try {
                setIsLoading(true)
                fetch(variablesPath.API_URL + 'updatepwd2', {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(dataSend),
                })
                    .then(response => {
                        return response.text();
                    })
                    .then(data => {
                        toast.success('Contraseña cambiada con éxito')
                    });
                handleClose()
            } catch (error) {
                toast.warning('BD don\'t work')
            } finally {
                setIsLoading(false)
            }

        } else {
            toast.warning('las contraseñas deben ser iguales')
        }
    }

    const rememberPassword = (e) => {
        setRememberPwd(e.target.checked);
        if (e.target.checked) {
            localStorage.setItem('password', pwd);
        } else {
            localStorage.removeItem('password');
        }
    }

    useEffect(() => {
        if (rememberPwd) setPwd(localStorage.getItem('password'))
    }, [rememberPwd])

    return (
        <>
            <ToastContainer position="bottom-right" />
            <div className="container-fluid ps-md-0">
                <div className="row g-0">
                    <div className="d-none d-md-flex col-md-4 col-lg-6 bg-image"></div>
                    <div className="col-md-8 col-lg-6">
                        {isLoading
                            ? <LoaderES />
                            : <div className="login d-flex align-items-center py-5">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-9 col-lg-8 mx-auto">
                                            {/* <form onSubmit={login}> */}
                                                <img src={logo} className="img-fluid mx-auto" alt="logo" />
                                                <div className="d-flex justify-content-center align-items-center mb-4">
                                                    <h4 className="login-heading mb-0 me-2">Welcome back!</h4>
                                                    <Clear />
                                                </div>
                                                <div className="form-floating mb-3 mx-3">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="floatingInput"
                                                        placeholder="User Name"
                                                        onChange={(e) => setUsuario(e.target.value)} />
                                                    <label htmlFor="floatingInput">User Name</label>
                                                </div>
                                                <div className="form-floating mb-3 mx-3">
                                                    <input type="password"
                                                        className="form-control"
                                                        id="floatingPassword"
                                                        placeholder="Password"
                                                        value={pwd || ''}
                                                        onChange={(e) => setPwd(e.target.value)} />
                                                    <label htmlFor="floatingPassword">Password</label>
                                                </div>
                                                <div className="form-check mb-3 mx-3">
                                                    <input className="form-check-input"
                                                        type="checkbox"
                                                        value=""
                                                        id="rememberPasswordCheck"
                                                        onChange={rememberPassword} />
                                                    <label className="form-check-label"
                                                        htmlFor="rememberPasswordCheck">
                                                        Remember password
                                                    </label>
                                                </div>
                                                <div className="d-grid">
                                                    <button /* type="submit" */
                                                        className="btn btn-lg btn-primary btn-login text-uppercase fw-bold mb-2 mx-3"
                                                        onClick={(e)=>login(e)}>
                                                        Sign in
                                                    </button>
                                                    <div className="text-center">
                                                        <a className="small color-logo-claro" href="#">Forgot password?</a>
                                                    </div>
                                                </div>
                                                <div className="sticky-bottom text-end mx-3 mt-5" style={{ fontSize: '9px' }} >
                                                    © 2023 LivenUP && Technology Wells. Todos los derechos reservados.
                                                </div>
                                            {/* </form> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Asignación de Contraseña</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 row">
                        <label htmlFor="staticEmail"
                            className="col-sm-2 col-form-label">Usuario</label>
                        <div className="col-sm-10">
                            <input type="text"
                                readonly
                                className="form-control-plaintext"
                                value={usuario} />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label htmlFor="inputPassword"
                            className="col-sm-2 col-form-label">Password</label>
                        <div className="col-sm-10">
                            <input type="password"
                                className="form-control"
                                id="inputPassword" />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label htmlFor="inputPassword2"
                            className="col-sm-2 col-form-label">Repetir Password</label>
                        <div className="col-sm-10">
                            <input type="password"
                                className="form-control"
                                id="inputPassword2" />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary"
                        onClick={handleClose}>
                        Close
                    </Button>

                    <Button variant="outline-primary"
                        onClick={() => savePass()}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}
