import React, { useState, useEffect } from 'react';
import { LoaderES } from '../../LoaderES';
import { Filtrar } from '../../Filtrar';
import { Pagination } from '../../Pagination';
import { toast } from 'react-toastify';
import { evaluate } from 'mathjs';
import { FormField, formButton } from '../../../config/minis';
import { getTableByIdBd, insertTableBd, ordenar, updateTableBd } from '../../../config/functions';

import ModalDefaultMb from './ModalDefaultMb';
import numeric from 'numeric';
import ConnectionWits from './ConnectionWits';
import ConnectionDb from './ConnectionDb';

const VariablesForm = ({ rig, rigs, setRig, mobile, variables, user, uid, colors }) => {

  const [formulacion, setFormulacion] = useState([])
  const [variablesFormulacion, setVariablesFormulacion] = useState([])
  const [documentosFiltrados, setDocumentosFiltrados] = useState([]);

  const [connectionType, setConnectionType] = useState('tcp');

  const [idFormulacion, setIdFormulacion] = useState(0)
  const [editar, setEditar] = useState(0)
  const [formType, setFormType] = useState('')
  const [formValue1, setFormValue1] = useState(0)
  const [formValue2, setFormValue2] = useState(0)
  const [formValue3, setFormValue3] = useState(0)
  const [formMa1, setFormMa1] = useState(4)
  const [formMa2, setFormMa2] = useState(10)
  const [formMa3, setFormMa3] = useState(20)
  const [formTestValue, setFormTestValue] = useState(0)
  const [formResult, setFormResult] = useState(0)
  const [formula, setFormula] = useState('x')
  const [dirModbus, setDirModbus] = useState(0)
  const [tx, setTx] = useState(false)
  const [var1, setVar1] = useState(0)
  const [var2, setVar2] = useState(0)

  const [isLoading, setIsLoading] = useState(false)

  const [registrosPorPagina, setRegistrosPorPagina] = useState(15);
  const [paginaActual, setPaginaActual] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(0)
  const [registrosPaginaActual, setRegistrosPaginaActual] = useState([])

  const [showDefaultMbModal, setShowDefaultMbModal] = useState(false);

  //---------------------------------------------

  const getFormulacion = async () => {
    if (variables.length === 0) return
    try {
      setIsLoading(true)
      const dataSend = rig
      const data = await getTableByIdBd(dataSend, 'getformula');
      if (data.length > 0) {
        setFormulacion(data)
      } else {
        setFormulacion([])
      }
    } catch (error) {
      setFormulacion([])
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => { getFormulacion() }, [rig, variables])
  useEffect(() => { completar() }, [formulacion])

  const completar = () => {
    const varYForm = variables.map(v => {
      const configTempo = formulacion.find(f => parseInt(f.nb_var_idn) === parseInt(v.nb_var_idn));
      return {
        ...v,
        ...configTempo,
        nb_var_idn: parseInt(v.nb_var_idn),
        nb_tldro_idn: parseInt(rig),
        idn_formulacion: configTempo ? parseInt(configTempo.idn_formulacion) : 0,
        dir_modbus: configTempo ? parseInt(configTempo.dir_modbus) : 0,
        tx: configTempo ? parseInt(configTempo.tx) : 0,
        tipo_formula: configTempo ? configTempo.tipo_formula : '',
        value1: configTempo ? parseInt(configTempo.value1) : 0,
        value2: configTempo ? parseInt(configTempo.value2) : 0,
        value3: configTempo ? parseInt(configTempo.value3) : 0,
        ma1: configTempo ? parseInt(configTempo.ma1) : 0,
        ma2: configTempo ? parseInt(configTempo.ma2) : 0,
        ma3: configTempo ? parseInt(configTempo.ma3) : 0,
        var1: configTempo ? parseInt(configTempo.var1) : 0,
        var2: configTempo ? parseInt(configTempo.var2) : 0,
        formula: configTempo ? configTempo.formula : 'x',
      };
    });
    setVariablesFormulacion(varYForm);
  };

  //---------------------------------------------

  useEffect(() => {
    setTotalPaginas(Math.ceil(documentosFiltrados.length / registrosPorPagina))
  }, [documentosFiltrados, registrosPorPagina])

  useEffect(() => {
    const inicio = (paginaActual - 1) * parseInt(registrosPorPagina)
    const fin = inicio + parseInt(registrosPorPagina)
    setRegistrosPaginaActual(variablesFormulacion.slice(inicio, fin))
  }, [paginaActual, totalPaginas])

  //---------------------------------------------

  useEffect(() => {

    //--------------------para 2 puntos
    function interpolarValor(minValor, maxValor, mAIngresado, minMA, maxMA) {

      const y0 = parseFloat(minValor)
      const y1y0 = parseFloat(maxValor) - parseFloat(minValor)
      const x1x0 = parseFloat(maxMA) - parseFloat(minMA)
      const xx0 = parseFloat(mAIngresado) - parseFloat(minMA)

      const valorInterpolado = y0 + (y1y0 * xx0 / x1x0);
      return parseFloat(valorInterpolado).toFixed(2);
    }
    //--------------------para 2 puntos

    //------------------- para 3 puntos
    function calcularCoeficientes(puntos) {
      // puntos es un array de objetos con la forma { x: mA, y: valor }
      const xs = puntos.map(p => p.x);
      const ys = puntos.map(p => p.y);

      // Crear la matriz A para la solución de mínimos cuadrados Ax = b
      const A = xs.map(x => [x * x, x, 1]);
      const b = ys;

      // Resolver Ax = b
      const coeficientes = numeric.solve(A, b);
      return coeficientes;
    }
    function interpolarValor3p(coeficientes, mAIngresado) {
      // Calcula el valor usando el polinomio cuadrático
      const valorInterpolado = coeficientes[0] * mAIngresado ** 2 + coeficientes[1] * mAIngresado + coeficientes[2];
      return parseFloat(valorInterpolado).toFixed(2);
    }
    //------------------- para 3 puntos

    //------------------- Avanzada
    function calcularConFormula(formula, valor) {
      try {
        // Reemplazar la variable en la fórmula con el valor proporcionado
        const formulaConValor = formula.replace(/x/g, `(${valor})`);

        // Evaluar la fórmula
        const resultado = evaluate(formulaConValor);

        return resultado;
      } catch (error) {
        console.error("Error al evaluar la fórmula: ", error);
        return null; // o manejar el error de alguna otra manera
      }
    }
    //------------------- Avanzada

    switch (formType) {
      case '2':
        const result = interpolarValor(formValue1, formValue3, formTestValue, formMa1, formMa3)
        setFormResult(result)
        break;
      case '3':
        const puntos = [
          { x: parseFloat(formMa1), y: parseFloat(formValue1) },
          { x: parseFloat(formMa2), y: parseFloat(formValue2) },
          { x: parseFloat(formMa3), y: parseFloat(formValue3) }
        ]
        const coeficientes = calcularCoeficientes(puntos);
        const result3p = interpolarValor3p(coeficientes, formTestValue)
        setFormResult(result3p)
        break
      case 'A':
        const resultFormula = calcularConFormula(formula, formTestValue);
        setFormResult(resultFormula);
      default:
        break;
    }
  }, [formTestValue])

  const asignarVrbl = (v) => {
    setIdFormulacion(v ? parseInt(v.idn_formulacion) : 0)
    setEditar(v ? parseInt(v.nb_var_idn) : 0)
    setDirModbus(v ? parseInt(v.dir_modbus) : 0)
    setTx(v ? v.tx : 0)
    setFormType(v ? parseInt(v.tipo_formula) : '')
    setFormMa1(v ? parseInt(v.ma1) : 0)
    setFormValue1(v ? parseInt(v.value1) : 0)
    setFormMa2(v ? parseInt(v.ma2) : 0)
    setFormValue2(v ? parseInt(v.value2) : 0)
    setFormMa3(v ? parseInt(v.ma3) : 0)
    setFormValue3(v ? parseInt(v.value3) : 0)
    setVar1(v ? parseInt(v.var1) : 0)
    setVar2(v ? parseInt(v.var1) : 0)
    setFormula(v ? v.formula : 'x')
    setFormResult(0)
    setFormTestValue(0)
  }

  const guardarVrbl = async () => {

    const nb_tldro_idn = parseInt(rig, 10);
    const dir_modbus = parseInt(dirModbus);

    if (tx && isNaN(dir_modbus)) {
      toast.warning('no hay una dirección modbus válida');
      return;
    }

    try {
      setIsLoading(true)
      const fecha = new Date();
      const dataSend = {
        nb_tldro_idn,
        nb_var_idn: parseInt(editar),
        vr_adt_usureg: user,
        dt_adt_fchreg: fecha.toISOString(),
        dir_modbus,
        tx: tx ? 1 : 0,
        tipo_formula: formType || '',
        value1: parseInt(formValue1) || 0,
        value2: parseInt(formValue2) || 0,
        value3: parseInt(formValue3) || 0,
        ma1: parseInt(formMa1) || 0,
        ma2: parseInt(formMa2) || 0,
        ma3: parseInt(formMa3) || 0,
        var1: var1 || 0,
        var2: var2 || 0,
        formula: formula || '',
        idn_formulacion: isNaN(idFormulacion) ? 0 : idFormulacion
      };

      if (dataSend.idn_formulacion === 0) {
        const response = await insertTableBd('createformula', dataSend);
        toast.success(response.data);
      } else {
        const response = await updateTableBd('updateformula', dataSend);
        toast.success(response.data);
      }

      setEditar(0)
      await getFormulacion()
    } catch (error) {
      toast.warning('bd don\'t work');
    } finally {
      setIsLoading(false)
    }
  }

  //----------------------------------------------
  //Modbus Read Connection
  const [intervalMbId, setIntervalMbId] = useState(null);

  const [formData, setFormData] = useState({
    connectionType: 'tcp',
    connectionOpen: false,
    responseData: [],
    port: 'COM5',
    ip: '192.168.1.18',
    baudRate: 9600,
    unitId: 1,
    tcpPort: 502,
    address: 1,
    quantity: 4,
    timeMs: 1,
    decimals: 4
  });

  const handleChangeFormData = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => {
      return {
        ...prevData,
        [name]: (name === 'address' || name === 'quantity' ||
          name === 'baudRate' || name === 'unitId' ||
          name === 'tcpPort' || name === 'address' ||
          name === 'quantity' || name === 'timeMs' ||
          name === 'decimals')
          ? parseInt(value) : value,
      };
    });
  };

  const toggleConnection = () => {
    if (formData.connectionOpen) {
      clearInterval(intervalMbId);
      setFormData((prevData) => ({
        ...prevData,
        responseData: [],
      }));
    } else {
      const newIntervalId = setInterval(readModbusData, (formData.timeMs * 1000));
      setIntervalMbId(newIntervalId);
    }

    setFormData((prevData) => ({
      ...prevData,
      connectionOpen: !prevData.connectionOpen,
    }));
  };

  const readModbusData = async () => {
    try {
      console.log(formData);
      console.log('readModbusdata');
      /* const response = await axios.post('http://localhost:3000/read-modbus', formData);
      setFormData((prevData) => ({
        ...prevData,
        responseData: response.data,
      })); */
    } catch (error) {
      console.error('Read Modbus Error:', error);
    }
  };

  //----------------------------------------------

  return (
    <>
      {variables.length === 0 || isLoading ?
        <LoaderES />
        : <>
          <hr />

          <ul className="nav nav-tabs mb-3">
            <li className="nav-item">
              <a className={`nav-link ${connectionType === 'tcp' ? 'active' : ''}`}
                onClick={() => setConnectionType('tcp')}>MB-TCP</a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${connectionType === 'rtu' ? 'active' : ''}`}
                onClick={() => setConnectionType('rtu')}>MB-RTU</a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${connectionType === 'wits' ? 'active' : ''}`}
                onClick={() => setConnectionType('wits')}>WITS</a>
            </li>
          </ul>


          <div className='row mt-1'>
            {connectionType === 'rtu' && (
              <>
                {FormField("port", "port", "text", formData.port, "PLC Port", handleChangeFormData, formData.connectionOpen)}
                {FormField("baudRate", "baudRate", "number", formData.baudRate, "Baud Rate", handleChangeFormData, formData.connectionOpen)}
              </>
            )}

            {connectionType === 'tcp' && (
              <> {/* TCP */}
                {FormField("ip", "ip", "text", formData.ip, "PLC IP", handleChangeFormData, formData.connectionOpen)}
                {FormField("tcpPort", "tcpPort", "number", formData.tcpPort, "TCP Port", handleChangeFormData, formData.connectionOpen)}
              </>
            )}

            {connectionType === 'wits' && <ConnectionWits />}

            {FormField("time", "time", "number", formData.timeMs, "Time", handleChangeFormData, formData.connectionOpen)}
            {FormField("time2", "time2", "number", formData.decimals, "Decimals", handleChangeFormData, formData.connectionOpen)}
            {formButton(toggleConnection, formData.connectionOpen, 'Mb')}
          </div>

          <hr className='mt-1' />

          {/* Database Connection */}
          <ConnectionDb
            rig={rig} setRig={setRig}
            rigs={rigs}
            setIsLoading={setIsLoading}
            getFormulacion={getFormulacion}
            uid={uid} />

          <hr className='mt-1' />

          <Filtrar
            documentos={variablesFormulacion}
            setDocumentosFiltrados={setDocumentosFiltrados}
            setRegistrosFiltrados={setRegistrosPaginaActual}
            registrosPorPagina={registrosPorPagina} 
            colors={colors}/>

          {!mobile && (
            <div className="table-responsive mt-3">
              <table className="table  table-hover table-sm align-middle table-bordered" id='myTable'
                style={{ color: colors.colorFont || '#000000' }}>
                <thead>
                  <tr>
                    <th scope="col">
                      <button className='btn btn-sm fa-solid fa-info btn-info'
                        onClick={() => setShowDefaultMbModal(true)} />
                    </th>
                    <th scope="col">Idn</th>
                    <th scope="col">TAG &nbsp;&nbsp;
                      <i className="fa-solid fa-arrow-down-1-9"
                        onClick={() => ordenar(1)}
                        style={{ cursor: "pointer" }} />
                    </th>
                    <th scope="col" >Variable &nbsp;&nbsp;
                      <i className="fa-solid fa-arrow-down-a-z"
                        onClick={() => ordenar(2)}
                        style={{ cursor: "pointer" }} />
                    </th>
                    <th scope="col" >UM &nbsp;&nbsp;
                      <i className="fa-solid fa-arrow-down-a-z"
                        onClick={() => ordenar(3)}
                        style={{ cursor: "pointer" }} />
                    </th>
                    <th>Modbus Dir </th>
                    <th>tx </th>
                    <th>Formula Type </th>
                    <th>Formula Parameters </th>
                    <th>test Value</th>
                    <th>result test value</th>
                  </tr>
                </thead>
                <tbody>
                  {registrosPaginaActual.map(vrbl => (
                    <tr key={vrbl.nb_var_idn} style={{ fontSize: '11px' }}>
                      <td>
                        {vrbl.nb_var_idn === editar
                          ? <button
                            style={{ color: colors.colorFont || '#000000' }}
                            className='btn btn-sm fa-solid fa-floppy-disk'
                            onClick={() => guardarVrbl()} />
                          : editar === 0
                            ? <button
                              style={{ color: colors.colorFont || '#000000' }}
                              className='btn btn-sm fa-solid fa-pencil'
                              disabled={rig === 0 || rig === 'new' || rig === ''}
                              onClick={() => asignarVrbl(vrbl)}
                            />
                            : null
                        }
                      </td>
                      <td scope="row">{vrbl.nb_var_idn}</td>
                      <td scope="row">{vrbl.vr_var_tag}</td>
                      <td>{vrbl.vr_var_nom}</td>
                      <td>{vrbl.vr_var_umd}</td>

                      <td>
                        <input className='form-control form-control-sm text-end'
                          type="number"
                          min={4000}
                          value={editar === vrbl.nb_var_idn ? dirModbus : vrbl.dir_modbus}
                          onChange={(e) => setDirModbus(e.target.value)}
                          disabled={editar !== vrbl.nb_var_idn} />
                      </td>

                      <td>
                        <div className="form-check form-switch">
                          <input className="form-check-input" type="checkbox"
                            role="switch"
                            id={`tx_${vrbl.nb_var_idn}`}
                            checked={editar === vrbl.nb_var_idn ? tx : vrbl.tx}
                            onChange={(e) => setTx(e.target.checked)}
                            disabled={editar !== vrbl.nb_var_idn}
                          />
                        </div>
                      </td>

                      <td>
                        <select className="form-select form-select-sm"
                          aria-label="select Type"
                          value={editar === vrbl.nb_var_idn ? formType : vrbl.tipo_formula}
                          disabled={editar !== vrbl.nb_var_idn}
                          onChange={(e) => setFormType(e.target.value)}>
                          <option value="">No Formula</option>
                          <option value="2">2 Points</option>
                          <option value="3">3 Points</option>
                          <option value="A">Advance</option>
                        </select>
                      </td>

                      <td>
                        {((vrbl.tipo_formula === '2' || vrbl.tipo_formula === '3') ||
                          (editar === vrbl.nb_var_idn && (formType === '2' || formType === '3'))) && (
                            <div className="input-group input-group-sm">
                              <span className="input-group-text">Min</span>
                              <input className='form-control form-control-sm text-end'
                                type="number"
                                min={4}
                                max={20}
                                value={editar === vrbl.nb_var_idn ? formMa1 : vrbl.ma1}
                                onChange={(e) => setFormMa1(e.target.value)}
                                disabled={(formType !== '2' || formType !== '3') && editar !== vrbl.nb_var_idn} />
                              <span className="input-group-text">mA</span>
                              <input className='form-control form-control-sm text-end'
                                type="number"
                                value={editar === vrbl.nb_var_idn ? formValue1 : vrbl.value1}
                                onChange={(e) => setFormValue1(e.target.value)}
                                disabled={(formType !== '2' || formType !== '3') && editar !== vrbl.nb_var_idn} />
                              <span className="input-group-text">{vrbl.vr_var_umd}</span>
                            </div>
                          )}

                        {(vrbl.tipo_formula === '3' || (editar === vrbl.nb_var_idn && formType === '3')) && (
                          <div className="input-group input-group-sm" >
                            <span className="input-group-text">Min</span>
                            <input className='form-control form-control-sm text-end'
                              type="number"
                              min={4}
                              max={20}
                              value={editar === vrbl.nb_var_idn ? formMa2 : vrbl.ma2}
                              onChange={(e) => setFormMa2(e.target.value)}
                              disabled={formType !== '3' && editar !== vrbl.nb_var_idn} />
                            <span className="input-group-text">mA</span>
                            <input className='form-control form-control-sm text-end'
                              type="number"
                              value={editar === vrbl.nb_var_idn ? formValue2 : vrbl.value2}
                              onChange={(e) => setFormValue2(e.target.value)}
                              disabled={formType !== '3' && editar !== vrbl.nb_var_idn} />
                            <span className="input-group-text">{vrbl.vr_var_umd}</span>
                          </div>
                        )}

                        {((vrbl.tipo_formula === '2' || vrbl.tipo_formula === '3') || (editar === vrbl.nb_var_idn && (formType === '2' || formType === '3'))) && (
                          <div className="input-group input-group-sm">
                            <span className="input-group-text">Min</span>
                            <input className='form-control form-control-sm text-end'
                              type="number"
                              min={4}
                              max={20}
                              value={editar === vrbl.nb_var_idn ? formMa3 : vrbl.ma3}
                              onChange={(e) => setFormMa3(e.target.value)}
                              disabled={(formType !== '2' || formType !== '3') && editar !== vrbl.nb_var_idn} />
                            <span className="input-group-text">mA</span>
                            <input className='form-control form-control-sm text-end'
                              type="number"
                              value={editar === vrbl.nb_var_idn ? formValue3 : vrbl.value3}
                              onChange={(e) => setFormValue3(e.target.value)}
                              disabled={(formType !== '2' || formType !== '3') && editar !== vrbl.nb_var_idn} />
                            <span className="input-group-text">{vrbl.vr_var_umd}</span>
                          </div>
                        )}

                        {vrbl.tipo_formula === 'A' || (editar === vrbl.nb_var_idn && formType === 'A') && (
                          <>
                            <div className="input-group input-group-sm">
                              <span className="input-group-text">var1</span>
                              <select className="form-select form-select-sm"
                                aria-label="select Type"
                                value={editar === vrbl.nb_var_idn ? var1 : vrbl.var1}
                                disabled={editar !== vrbl.nb_var_idn}
                                onChange={(e) => setVar1(e.target.value)}>
                                {variables.map(v => (
                                  <option value="" key={v.nb_var_idn}>{v.vr_var_nom}</option>
                                ))}

                              </select>
                              <span className="input-group-text">var2</span>
                              <select className="form-select form-select-sm"
                                aria-label="select Type"
                                value={editar === vrbl.nb_var_idn ? var2 : vrbl.var2}
                                disabled={editar !== vrbl.nb_var_idn}
                                onChange={(e) => setVar2(e.target.value)}>
                                {variables.map(v => (
                                  <option value="" key={v.nb_var_idn}>{v.vr_var_nom}</option>
                                ))}
                              </select>
                            </div>

                            <div className="input-group input-group-sm">
                              <span className="input-group-text">Formula</span>
                              <input className='form-control form-control-sm text-end'
                                type="text"
                                value={editar === vrbl.nb_var_idn ? formula : vrbl.formula}
                                onChange={(e) => setFormula(e.target.value)}
                                disabled={vrbl.tipo_formula !== 'A' || editar !== vrbl.nb_var_idn} />
                            </div>
                          </>
                        )}
                      </td>

                      <td>
                        <input className='form-control form-control-sm'
                          type="number"
                          value={editar === vrbl.nb_var_idn ? formTestValue : 0}
                          disabled={formType === '' || editar !== vrbl.nb_var_idn}
                          onChange={(e) => setFormTestValue(e.target.value)} />
                      </td>

                      <td>{`${editar === vrbl.nb_var_idn
                        ? (isNaN(formResult) ? 0 : formResult + vrbl.vr_var_umd)
                        : 0}`}</td>

                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          <Pagination
            paginaActual={paginaActual}
            totalPaginas={totalPaginas}
            setPaginaActual={setPaginaActual}
            registrosPorPagina={registrosPorPagina}
            setRegistrosPorPagina={setRegistrosPorPagina}
            documentosFiltrados={documentosFiltrados}
            mobile={mobile}
            colors={colors}
          />

        </>
      }
      <ModalDefaultMb
        show={showDefaultMbModal}
        handleClose={() => setShowDefaultMbModal(false)}
      />
    </>
  )
}

export default VariablesForm
