import React, { useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap';
import Highcharts from "highcharts/highstock";
import { insertTableBd } from '../../config/functions';

export const ModalComments= ({ handleClose, xComentario, show, user, rig }) => {

    const [comment, setComment] = useState('');

    const handleSubmitComment = async () => {
        const charts = Highcharts.charts.filter(chart => chart !== undefined);
        const flagsChart = charts.find(chart => {
            return chart.series.some(series => series.type === 'scatter');
        });
        if (flagsChart) {
            await agregarComentario(comment, flagsChart);
            setComment('');
            handleClose()
        }
    };

    const agregarComentario = async (comentario, chart) => {

        const date = new Date(xComentario.x /* + 18000000 */) 
        const fecha = new Date()
        const usuario = user

        const dataComentarioSend = {
            nb_tldro_idn: rig,
            nb_var_idn: parseInt(xComentario.varIdn),
            dt_com_fch: date.toISOString(),
            nb_com_prof: 0,
            nb_com_val: comentario,//xComentario.y,
            vr_adt_usureg: usuario,
            dt_adt_fchreg: fecha.toISOString(),
        }

        await insertTableBd('createComment', dataComentarioSend)

        const flagsSeries = chart.series.find(series => series.type === 'scatter');
        if (flagsSeries) {
            const point = {
                x: xComentario.x,
                y: 0,
                comment: comentario,
                user: usuario,
                dateInsert: fecha.toISOString(),
            }
            flagsSeries.addPoint(point);
            chart.redraw();
        } else {
            console.error('La serie de comentarios no está disponible');
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>

            <Modal.Header closeButton>
                <Modal.Title>Agregar Comentario</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Comentario</Form.Label>
                        <Form.Control
                            type="text"
                            value={comment}
                            onChange={e => setComment(e.target.value)}
                            placeholder="Ingrese su comentario"
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary"
                    onClick={handleClose}>
                    Cerrar
                </Button>
                <Button variant="primary"
                    onClick={() => handleSubmitComment()}>
                    Guardar Comentario
                </Button>
            </Modal.Footer>

        </Modal>
    )
}
