import React, { Fragment, useEffect } from 'react';
import { Offcanvas } from 'react-bootstrap';

export const Config = ({ show, handleClose, colors, setColors, setTheme, theme }) => {

    const initialColors = {
        colorBg: "#050505",
        colorFont: "#08e740",

        ldColorAlarm: "#ef0b0b",
        ldColorBg: "#121212",
        ldColorBorder: "#9c9c9c",
        ldColorFont: "#ffffff",
        ldColorMeasure: "#00ff1e",

        grColorBg: "#1f1e1e",
        grColorAlarm: '#ff0000',
        grColorBg: '#050505',
        grColorBorder: "#12a114",
        grColorFont: "#2e9004",
        grColorMeasure: "#1dfa00",
        grColor1_1: "#07e40b",
        grColor1_2: "#f308f7",
        grColor2_1: "#07e40b",
        grColor2_2: "#f308f7",
        grColor3_1: "#07e40b",
        grColor3_2: "#f308f7",
        grColor4_1: "#07e40b",
        grColor4_2: "#f308f7",
        grColor5_1: "#07e40b",
        grColor5_2: "#f308f7",
    };

    useEffect(() => {
        const storedColors = JSON.parse(localStorage.getItem('colors')) || {};
        const mergedColors = { ...initialColors, ...storedColors };
        setColors(mergedColors);
    }, []);

    const handleColorChange = (key, value) => {
        setColors((prevColors) => {
            const newColors = { ...prevColors, [key]: value };
            localStorage.setItem('colors', JSON.stringify(newColors));
            localStorage.setItem('colorsCustom', JSON.stringify(newColors));
            return newColors;
        });
    };

    const trItemCanvas = (texto, icono, colorKey, unir) => {
        return (
            <tr>
                <td> <i className={`${icono} me-2 text-center`}
                    style={{ color: colors.colorFont || '#000000' }} />
                </td>
                <td colSpan={unir ? 2 : 1}>{texto}</td>
                <td className='text-end'>
                    <input
                        type="color"
                        className="color-picker border-0"
                        style={{ width: '20px', height: '20px', cursor: 'pointer' }}
                        value={colors[colorKey]}
                        onChange={(e) => handleColorChange(colorKey, e.target.value)}
                    />
                </td>
            </tr>
        );
    };

    const tr2ItemCanvas = (track, texto1, texto2, icono, colorKey1, colorKey2) => {
        return (
            <Fragment>
                <tr>
                    <td rowSpan={2}> <i className={`${icono} me-2 text-center`}
                        style={{ color: colors.colorFont || '#000000' }} />
                    </td>
                    <td rowSpan={2}>{track}</td>
                    <td >{texto1}</td>
                    <td className='text-end'>
                        <input
                            type="color"
                            style={{ width: '20px', height: '20px', cursor: 'pointer' }}
                            className="color-picker border-0"
                            value={colors[colorKey1]}
                            onChange={(e) => handleColorChange(colorKey1, e.target.value)}
                        />
                    </td>
                </tr>
                <tr>
                    <td >{texto2}</td>
                    <td className='text-end'>
                        <input
                            type="color"
                            style={{ width: '20px', height: '20px', cursor: 'pointer' }}
                            className="color-picker border-0"
                            value={colors[colorKey2]}
                            onChange={(e) => handleColorChange(colorKey2, e.target.value)}
                        />
                    </td>
                </tr>
            </Fragment>
        );
    };

    const tableCanvas = (titulo, contenido, unir) => {
        return (
            <table className="table table-sm table-hover align-middle" style={{ color: colors.colorFont || '#000000', fontSize: '12px' }}>
                <thead><tr><th colSpan={unir ? 4 : 3} className='text-center'>{titulo}</th></tr></thead>
                <tbody> {contenido} </tbody>
            </table>
        );
    }

    const tdButtonTheme = (th, icono, titulo, align) => {
        return (
            <td className={`text-${align}`}>
                <button
                    className={`btn btn-sm btn-outline-${theme === th ? 'info' : 'light' ? 'secondary' : 'light'}`}
                    onClick={() => setTheme(th)}>
                    <i className={`${icono} me-2`} /> {titulo}
                </button>
            </td>
        )
    }

    return (
        <Offcanvas show={show} onHide={handleClose} placement="end"
            style={{
                color: colors.colorFont || '#000000',
                backgroundColor: theme === 'dark' ? '#0b2948' : colors.colorBg || '#FFFFFF',
                border: `1px solid ${colors.colorFont || '#000000'}`,
            }}>
            <Offcanvas.Header >
                <Offcanvas.Title>Theme {theme}</Offcanvas.Title>
                <button type="button"
                    className="btn fa-solid fa-times"
                    onClick={handleClose}
                    aria-label="Close"
                    style={{ color: colors.colorFont || '#000000' }} />
            </Offcanvas.Header>
            <Offcanvas.Body className="p-3">
                <table className="table">
                    <tbody>
                        <tr>
                            {tdButtonTheme('dark', 'fa-solid fa-moon', 'Dark', 'start')}
                            {tdButtonTheme('light', 'fa-solid fa-sun', 'Light', 'center')}
                            {tdButtonTheme('custom', 'fa-solid fa-palette', 'Custom', 'end')}
                            {theme === 'custom' && (
                                <td className='text-end'>
                                    <button
                                        className="btn btn-sm btn-outline-danger"
                                        onClick={() => {
                                            localStorage.setItem('colorsCustom', JSON.stringify(initialColors));
                                            setColors(initialColors)
                                        }}>
                                        <i className="fa-solid fa-eraser me-2" />
                                    </button>
                                </td>
                            )}
                        </tr>
                    </tbody>
                </table>

                {theme === 'custom' && (
                    <>
                        {tableCanvas('General',
                            <Fragment>
                                {trItemCanvas('BackGround', 'fa fa-square', 'colorBg')}
                                {trItemCanvas('Font', 'fa fa-font', 'colorFont')}
                            </Fragment>
                        )}

                        {tableCanvas('Live data',
                            <Fragment>
                                {trItemCanvas('Alarm', 'fa-solid fa-exclamation', 'ldColorAlarm')}
                                {trItemCanvas('BackGround', 'fa fa-square', 'ldColorBg')}
                                {trItemCanvas('Border', 'fa-solid fa-border-all', 'ldColorBorder')}
                                {trItemCanvas('Font', 'fa fa-font', 'ldColorFont')}
                                {trItemCanvas('Measure', 'fa-solid fa-1', 'ldColorMeasure')}
                            </Fragment>
                        )}

                        {tableCanvas('Graphs',
                            <Fragment>
                                {trItemCanvas('Alarm', 'fa-solid fa-exclamation', 'grColorAlarm', true)}
                                {trItemCanvas('BackGround', 'fa fa-square', 'grColorBg', true)}
                                {trItemCanvas('Border', 'fa-solid fa-border-all', 'grColorBorder', true)}
                                {trItemCanvas('Font', 'fa fa-font', 'grColorFont', true)}
                                {trItemCanvas('Measure', 'fa-solid fa-1', 'grColorMeasure', true)}
                                {tr2ItemCanvas('Track1', 'Line1', 'Line2', 'fa-solid fa-chart-line', 'grColor1_1', 'grColor1_2')}
                                {tr2ItemCanvas('Track2', 'Line1', 'Line2', 'fa-solid fa-chart-line', 'grColor2_1', 'grColor2_2')}
                                {tr2ItemCanvas('Track3', 'Line1', 'Line2', 'fa-solid fa-chart-line', 'grColor3_1', 'grColor3_2')}
                                {tr2ItemCanvas('Track4', 'Line1', 'Line2', 'fa-solid fa-chart-line', 'grColor4_1', 'grColor4_2')}
                                {tr2ItemCanvas('Track5', 'Line1', 'Line2', 'fa-solid fa-chart-line', 'grColor5_1', 'grColor5_2')}
                            </Fragment>, true
                        )}
                    </>
                )}


            </Offcanvas.Body>
        </Offcanvas>
    );
};