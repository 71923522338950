import React from 'react'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const Pits = ({rig, mobile}) => {

    const options = {
        chart: {
          type: 'column' // o 'area' dependiendo de tus preferencias
        },
        title: {
          text: 'Nivel de Lodo en Tanques'
        },
        xAxis: {
          categories: ['Pildora', 'Succion', 'Reserva', 'Viaje']
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Lodo (BBL)'
          }
        },
        plotOptions: {
            column: {
              borderRadius: 5, // Bordes redondeados para simular un tanque
              dataLabels: {
                enabled: true
              }
            }
          },
        series: [{
          name: 'Nivel de Lodo',
          data: [300, 400, 500, 450], // datos dinámicos de tus tanques
          color: '#7cb5ec'
        }]
      };

    return (
        <>
            Pits
            <HighchartsReact highcharts={Highcharts} options={options} />
        </>
    )
}

export default Pits