
import { AppRouter } from './router/Router';

function App() {
  
  return (
    <>
    <AppRouter/>
    </>
  );
}

export default App;
