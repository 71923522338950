import React from 'react'
import { Modal, Button, FormControl } from 'react-bootstrap';
import { insertTableBd, updateTableBd } from '../config/functions'
import { toast } from 'react-toastify'

const ModalAlarm = ({
    varAlarm,
    alarmMin,
    setAlarmMin,
    alarmMax,
    setAlarmMax,
    alarmExist,
    functionN,
    rig,
    varAlarmIdn,
    alarmId,
    show,
    handleClose,
    uid,
    user
}) => {

    const createAlarm = async () => {
        const fecha = new Date()

        if (parseInt(alarmMax) === 0 && parseInt(alarmMin) === 0) {
            toast.warning('Debe ingresar el valor máximo')
        } else if (parseInt(alarmMax) <= parseInt(alarmMin)) {
            toast.warning('El valor máximo debe ser mayor al mínimo')
        } else {
            try {
                const dataSend = {
                    nb_tldro_idn: rig,
                    nb_var_idn: parseInt(varAlarmIdn),
                    nb_usurio_idn: uid,
                    nb_alarm_min: parseInt(alarmMin),
                    nb_alarm_max: parseInt(alarmMax),
                    vr_adt_usureg: 'admin',
                    dt_adt_fchreg: fecha.toISOString()
                }

                await insertTableBd('createalarm', dataSend)
                toast.success('Alarma creada correctamente');
                handleClose()
                if (typeof functionN === 'function') {
                    functionN(rig);
                }
            } catch (error) {
                toast.warning('bd don\'t work');
            }
        }
    }

    const updateAlarm = async () => {
        const fecha = new Date()

        if (parseInt(alarmMax) === 0 && parseInt(alarmMin) === 0) {
            toast.warning('Debe ingresar el valor máximo')
        } else if (parseInt(alarmMax) <= parseInt(alarmMin)) {
            toast.warning('El valor máximo debe ser mayor al mínimo')
        } else {
            try {
                const dataSend = {
                    nb_alarm_idn: parseInt(alarmId),
                    nb_alarm_min: parseInt(alarmMin),
                    nb_alarm_max: parseInt(alarmMax),
                    vr_adt_usureg: user,
                    dt_adt_fchreg: fecha.toISOString()
                }
                await updateTableBd('updatealarm', dataSend)
                toast.success('Alarma actualizada correctamente');
                handleClose()
                if (typeof functionN === 'function') {
                    functionN(rig);
                }
            } catch (error) {
                toast.warning('bd don\'t work');
            }

        }
    }

    return (
        <Modal show={show} onHide={handleClose} id="alarmModal" aria-labelledby="alarmModalLabel">
            <Modal.Header closeButton>
                <Modal.Title>
                    Alarma para la variable:
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row g-3 justify-content-center">
                    <div className="col-3">
                        <FormControl
                            type="number"
                            placeholder="min"
                            value={parseInt(alarmMin)}
                            onChange={(e) => setAlarmMin(e.target.value)}
                        />
                    </div>
                    <div className="col-auto">
                        <label>{varAlarm}</label>
                    </div>
                    <div className="col-3">
                        <FormControl
                            type="number"
                            placeholder="Max"
                            value={parseInt(alarmMax)}
                            onChange={(e) => setAlarmMax(e.target.value)}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={() => (parseInt(alarmExist) === 1 ? updateAlarm() : createAlarm())}
                >
                    {parseInt(alarmExist) === 1 ? 'Update ' : 'Save'} changes
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalAlarm