import React, { useState, useEffect, useRef } from "react";
import { toast } from 'react-toastify';
import { ModalVarIzq } from "./ModalVarIzq";
import ModalAlarm from "../ModalAlarm";

import {
    deleteBd,
    getTableByIdBd,
    getVariables,
    insertTableBd,
    updateTableBd,
    validarMobil
} from "../../config/functions";

import './css/livegraphs.css';


export const GrillaIzq = ({ r, mobile, variables, tiempoIntervalo, dataHeredada, user, uid, colors }) => {

    //---------------------------------------------------------------------------------------------------
    const [variablesIzquierda, setVariablesIzquierda] = useState([])
    const [plantillaIzq, setPlantillaIzq] = useState([])
    const [datosIzquierda, setDatosIzquierda] = useState([])
    //----------------------------------------------------------------------------------------------------
    const intervalRefLeft = useRef(null);
    const [posicion, setPosicion] = useState(0)
    //----------------------------------------------------------------------------------------------------
    const [nb, setNb] = useState('')
    const nbRef = useRef('')
    //----------------------------------------------------------------------------------------------------
    const [updateVar, setUpdateVar] = useState(0)
    const [selectedVar, setSelectedVar] = useState({})
    const [posIzq, setPosIzq] = useState(0)

    //---------------------------------------------------------------------

    const [varAlarm, setVarAlarm] = useState(0)
    const [varAlarmIdn, setVarAlarmIdn] = useState('')
    const [alarmMin, setAlarmMin] = useState(0)
    const [alarmMax, setAlarmMax] = useState(0)
    const [alarmExist, setAlarmExist] = useState(0)
    const [alarmId, setAlarmId] = useState(0)

    //--------------------------------------------------------------

    const [showModalAlarm, setShowModalAlarm] = useState(false);
    const handleCloseModalAlarm = () => setShowModalAlarm(false);

    const [showModalVarIzq, setShowModalVarIzq] = useState(false);
    const handleCloseModalVarIzq = () => setShowModalVarIzq(false);

    //--------------------------------------------------------------

    useEffect(() => { return () => { if (intervalRefLeft.current) clearInterval(intervalRefLeft.current) } }, []);

    useEffect(() => {
        if (intervalRefLeft.current) clearInterval(intervalRefLeft.current)
        setDatosIzquierda([])
        if (!r || r === 0 || r === '') return;
        renderLiveDataLeft();
        intervalRefLeft.current = setInterval(() => { renderLiveDataLeft() }, tiempoIntervalo);
    }, [r])

    useEffect(() => { nbRef.current = nb }, [nb])

    //---------------------------------------------------------------------

    const renderLiveDataLeft = async () => {

        if (mobile) return
        const dataSend = r + ':' + user
        const data = await getTableByIdBd(dataSend, 'getvarsalarmgraphizq')

        if (data.length === 0) {
            setVariablesIzquierda([])
            setDatosIzquierda([]);
            setNb('')
            return;
        }

        let valorArr = data.find(r => r.dt_mda_fch !== null);
        if (!valorArr?.dt_mda_fch) {
            setVariablesIzquierda([])
            setDatosIzquierda([]);
            setNb('')
            return;
        }

        const nuevaFecha = valorArr.dt_mda_fch;
        //console.log('nuevaFechaIzq', nuevaFecha);
        setPlantillaIzq(data);

        if (nbRef.current !== nuevaFecha) nbRef.current = nuevaFecha;
    };

    /* Asignar Variables de la izq */
    useEffect(() => {
        let ordenVariables = []
        let datosIzq = []

        if (plantillaIzq.length >= 1) {
            for (let index = 0; index < 6; index++) {
                let k = 0
                plantillaIzq.forEach(di => {
                    if (index === parseInt(di.nb_datos_grafica_orden)) {
                        k = 1
                        ordenVariables.push(di.nb_var_idn)
                        datosIzq.push(di)
                    }
                });
                if (k === 0) {
                    ordenVariables.push('0')
                    datosIzq.push('')
                }
            }
            setVariablesIzquierda(ordenVariables)
            setDatosIzquierda(datosIzq);
        }
    }, [plantillaIzq])

    //---------------------------------------------------------------------

    const addVarMatrizIzq = async (ld, pos) => {
        const fecha = new Date()
        const dataSend = {
            nb_tldro_idn: parseInt(r),
            nb_var_idn: parseInt(ld.nb_var_idn),
            nb_usurio_idn: parseInt(uid),
            nb_datos_grafica_orden: parseInt(pos),
            vr_adt_usureg: user,
            dt_adt_fchreg: fecha.toISOString()
        }
        const data = await insertTableBd('createvarmatrizizq', dataSend)
        addLineIzq(data, pos)
    }

    const addLineIzq = (datosVariable, posi) => {
        const datosIzqTemporal = [...datosIzquierda]
        const varsIzqTemporal = [...variablesIzquierda]
        const variableEncontrada = variables.find(var_i => var_i.nb_var_idn === datosVariable.nb_var_idn);
        if (variableEncontrada) {
            datosIzqTemporal[posi] = {
                ...datosVariable,
                ...variableEncontrada,
                nb_mda_val: 0,
                nb_mda_prof: 0
            }

            varsIzqTemporal[posi] = datosVariable.nb_var_idn
            setVariablesIzquierda(varsIzqTemporal)
            setDatosIzquierda(datosIzqTemporal)
            toast.success("Se adiciona variable");
        } else {
            toast.error("Variable no encontrada.");
        }
    }

    /* actualizar variable a la matriz guardada */
    const updateVarMatrizIzq = async (ld, pos) => {
        const fecha = new Date()
        const dataSend = {
            nb_datos_grafica_idn: parseInt(updateVar),
            nb_tldro_idn: parseInt(r),
            nb_var_idn: parseInt(ld.nb_var_idn),
            nb_usurio_idn: parseInt(uid),
            vr_adt_usureg: user,
            dt_adt_fchreg: fecha.toISOString()
        }
        const data = await updateTableBd('updatevarmatrizizq', dataSend)
        addLineIzq(data, pos)
    }

    //-------------------------------------------------------------------

    const findAlarm = (ld) => {

        setVarAlarm(ld.vr_var_nom)
        setVarAlarmIdn(ld.nb_var_idn)

        if (ld.nb_alarm_min !== null) {
            setAlarmMin(ld.nb_alarm_min)
            setAlarmMax(ld.nb_alarm_max)
            setAlarmId(ld.nb_alarm_idn)
            setAlarmExist(1)
        } else {
            setAlarmMin(0)
            setAlarmMax(0)
            setAlarmExist(0)
        }
    }

    //------------------------------------------------------------------------

    const deleteVarMatrizIzq = async (id, posi) => {
        try {
            if (window.confirm('Esta seguro de continuar?')) {
                await deleteBd(id, 'deletevarmatrizizq')
                deleteLineIzq(posi)
            }
        } catch (error) {
            toast.warning('bd don\'t work');
        }
    }

    const deleteLineIzq = (posi) => {
        const datosIzquierdaTemporal = [...datosIzquierda]
        const varsIzqTemporal = [...variablesIzquierda]
        datosIzquierdaTemporal[posi] = ''
        varsIzqTemporal[posi] = ''
        setDatosIzquierda(datosIzquierdaTemporal)
        setVariablesIzquierda(varsIzqTemporal)
    }

    const renderDato = (dato) => {

        let d = datosIzquierda[dato]

        if (dataHeredada.length > 0) {
            const busqueda = dataHeredada.find(d => d.nb_var_idn === datosIzquierda[dato]?.nb_var_idn)
            d = busqueda ? { ...d, ...busqueda } : { ...d, ...datosIzquierda[dato] }
        }

        const nbAlarmMax = parseFloat(d?.nb_alarm_max)?.toFixed(2) || 0;
        const nbMdaVal = parseFloat(d?.nb_mda_val)?.toFixed(2);
        const nbAlarmMin = parseFloat(d?.nb_alarm_min)?.toFixed(2);

        return (
            r && (
                <div className="row " style={{ flexGrow: 1 }}>

                    <div className="card h-100 d-flex flex-column justify-content-center"
                        style={{ background: colors.grColorBg || '#ffffff', borderColor: colors.grColorBorder || '#000000' }}>
                        <div className="card-body text-center p-0 m-0 d-flex flex-column justify-content-center"
                            style={{ color: colors.grColorFont || '#000000' }}>
                            {(d && d !== '' && !isNaN(d.nb_mda_val))
                                ? <>
                                    <div className="row" >
                                        <div className="col-1 p-0 m-0"></div>
                                        <div className="col-10">
                                            <h6 className="card-title update-var m-0"
                                                style={{ fontSize: mobile ? '11px' : '15px' }}
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title="set Var"
                                                onClick={() => {
                                                    setUpdateVar(d.nb_datos_grafica_idn)
                                                    setPosicion(d.nb_var_idn)
                                                    setPosIzq(dato)
                                                    setShowModalVarIzq(true)
                                                }}>
                                                {d.vr_var_nom}
                                            </h6>
                                        </div>

                                        <div className="col-1 p-0 m-0">
                                            <span style={{ cursor: 'pointer' }}>{<i className="fa fa-times fa-xs"
                                                onClick={() => deleteVarMatrizIzq(parseInt(d.nb_datos_grafica_idn), dato)}>
                                            </i>}</span>
                                        </div>

                                    </div>

                                    <h4 className={nbAlarmMax >= 0
                                        ? (nbMdaVal >= nbAlarmMin && nbMdaVal <= nbAlarmMax)
                                            ? "card-text m-0 alarm-var"
                                            : "card-text m-0 red-alarm"
                                        : "card-text alarm-var m-0"}
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="set Alarm"
                                        onClick={() => {
                                            findAlarm(d)
                                            setShowModalAlarm(true)
                                        }}
                                    >
                                        {d.vr_var_umd === 'no/yes'
                                            ? d.nb_mda_val === '0'
                                                ? 'NO' : 'YES'
                                            :
                                            parseFloat(parseFloat(d.nb_mda_val) - parseInt(parseFloat(d.nb_mda_val))).toFixed(2) > 0
                                                ? parseFloat(d.nb_mda_val).toFixed(2)
                                                : parseFloat(d.nb_mda_val).toFixed(0)
                                        }
                                    </h4>

                                    <div className="row m-0 p-0" style={{ fontSize: '13px' }}>
                                        <p className="m-0">{datosIzquierda[dato].vr_var_umd}</p>
                                    </div>
                                </>
                                : <>
                                    <h6 className="card-title" style={{ fontSize: '11px' }}>Seleccionar</h6>
                                    <h3 className="card-text">
                                        <button className='btn btn-outline-primary'
                                            onClick={() => {
                                                setUpdateVar(0)
                                                setPosicion(0)
                                                setPosIzq(dato)
                                                setShowModalVarIzq(true)
                                            }}>
                                            <i className="fa-solid fa-plus"></i>
                                        </button>
                                    </h3>
                                    <p></p>
                                </>
                            }
                        </div>
                    </div>
                </div >
            ))
    }

    //------------------------------------------------------------------------

    useEffect(() => {
        document.documentElement.style.setProperty('--color-default', colors.grColorFont);
        document.documentElement.style.setProperty('--color-measure', colors.grColorMeasure);
        document.documentElement.style.setProperty('--color-alarm', colors.grColorAlarm);
    }, [colors]);

    //------------------------------------------------------------------------

    return (
        <>
            {(r && datosIzquierda && !mobile) && (
                <div className="col-sm-1 wc-responsive d-flex flex-column justify-content-between"
                    style={{ height: mobile ? '0%' : window.innerHeight - 252 }}>
                    {renderDato(0)}
                    {renderDato(1)}
                    {renderDato(2)}
                    {renderDato(3)}
                    {renderDato(4)}
                    {renderDato(5)}
                </div >
            )}

            <ModalVarIzq
                variables={variables}
                updateVar={updateVar}
                posicion={posicion}
                setPosicion={setPosicion}
                posIzq={posIzq}
                setSelectedVar={setSelectedVar}
                selectedVar={selectedVar}
                addVarMatrizIzq={addVarMatrizIzq}
                updateVarMatrizIzq={updateVarMatrizIzq}
                show={showModalVarIzq}
                handleClose={handleCloseModalVarIzq}
            />

            <ModalAlarm
                rig={r}
                varAlarm={varAlarm}
                alarmMin={alarmMin}
                alarmMax={alarmMax}
                alarmExist={alarmExist}
                varAlarmIdn={varAlarmIdn}
                alarmId={alarmId}
                setAlarmMin={setAlarmMin}
                setAlarmMax={setAlarmMax}
                functionN={renderLiveDataLeft}
                show={showModalAlarm}
                handleClose={handleCloseModalAlarm}
                uid={uid}
                user={user}
            />

        </>
    );
};