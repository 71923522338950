import React, { useState } from 'react';
import { FormField, formButton, formSelect } from '../../../config/minis';
import { Form, Button, InputGroup, Modal } from 'react-bootstrap';
import { insertTableBd } from '../../../config/functions';
import { toast } from 'react-toastify';

const ConnectionDb = ({ rig, rigs, setRig, setIsLoading, getFormulacion, uid }) => {

    const [intervalDbId, setIntervalDbId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [rigName, setRigName] = useState('');
    const [rigLocation, setRigLocation] = useState('');
    const [rigDescription, setRigDescription] = useState('');
    const [rigLatitude, setRigLatitude] = useState('');
    const [rigLongitude, setRigLongitude] = useState('');

    //---------------------------------------------

    const handleCloseModal = () => {
        setShowModal(false)
        setRig(0)
    };

    const [formDataDb, setFormDataDb] = useState({
        connectionOpen: false,
        dbIp: '34.66.14.53',
        dbPort: 5432,
        dbUser: 'onedig',
        dbPassword: 'onedig2015',
        rig,
        timeMs: 1
    });

    const handleChangeFormDataDb = (event) => {
        const { name, value } = event.target;
        if (name === 'rig' && value !== "new") setRig(value)
        if (name === 'rig' && value === "new") setShowModal(true);
        setFormDataDb((prevData) => {
            return {
                ...prevData,
                [name]: (name === 'dbPort') ? parseInt(value) : value,
            };
        });
    };

    const toggleConnectionDb = () => {

        if (formDataDb.connectionOpen) {
            clearInterval(intervalDbId);
            setFormDataDb((prevData) => ({
                ...prevData,
                responseData: [],
            }));
        } else {
            const newIntervalId = setInterval(readDataBaseData, (formDataDb.timeMs * 1000));
            setIntervalDbId(newIntervalId);
        }

        setFormDataDb((prevData) => ({
            ...prevData,
            connectionOpen: !prevData.connectionOpen,
        }));
    };

    const readDataBaseData = async () => {
        console.log('readDataBaseData');
        console.log(formDataDb);
    }

    const handleCreateRig = async () => {
        try {
            setIsLoading(true)

            const dataSend = {
                vr_tldro_nom: `${rigName}-${rigLocation}`,
                vr_tldro_des: rigDescription,
                vr_adt_usureg: uid,
                dt_adt_fchreg: new Date().toISOString(),
                nb_tldro_lat: rigLatitude,
                nb_tldro_lon: rigLongitude
            }

            //console.log(dataSend);

            const response = await insertTableBd('createrig', dataSend);
            toast.success(response.data);

            setShowModal(false);
            setRigName('');
            setRigDescription('');
            setRigLatitude('');
            setRigLongitude('');
            setRig(response.data);
            setFormDataDb((prevData) => ({
                ...prevData,
                rig: response.data,
            }));
            await getFormulacion()
        } catch (error) {
            toast.warning('bd don\'t work');
        } finally {
            setIsLoading(false)
        }
    }

    //---------------------------------------------

    return (
        <>
            <div className='row mt-1'>
                {FormField("dbIp", "dbIp", "text", formDataDb.dbIp, "DB IP/URL", handleChangeFormDataDb, formDataDb.connectionOpen)}
                {FormField("dbPort", "dbPort", "number", formDataDb.dbPort, "DB Port", handleChangeFormDataDb, formDataDb.connectionOpen)}
                {FormField("dbUser", "dbUser", "text", formDataDb.dbUser, "DB User", handleChangeFormDataDb, formDataDb.connectionOpen)}
                {FormField("dbPassword", "dbPassword", "password", formDataDb.dbPassword, "DB Password", handleChangeFormDataDb, formDataDb.connectionOpen)}
                {formSelect('rig', handleChangeFormDataDb, formDataDb.connectionOpen, formDataDb.rig, 'Select Rig', rigs, ['new', 'Create New Rig'])}
                {formButton(toggleConnectionDb, formDataDb.connectionOpen, 'Db')}
            </div>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Rig</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputGroup controlId="formRigName" className='mb-3'>
                        <InputGroup.Text>Rig Name</InputGroup.Text>
                        <Form.Control type="text"
                            placeholder="Rig-XXXX"
                            value={rigName}
                            onChange={(e) => setRigName(e.target.value)} />
                    </InputGroup>
                    <InputGroup controlId="formRigName" className='mb-3'>
                        <InputGroup.Text>Well Site</InputGroup.Text>
                        <Form.Control type="text"
                            placeholder="Well-Site-001"
                            value={rigLocation}
                            onChange={(e) => setRigLocation(e.target.value)} />
                    </InputGroup>
                    <InputGroup controlId="formRigDescription" className='mb-3'>
                        <InputGroup.Text>Rig Description</InputGroup.Text>
                        <Form.Control type="text"
                            placeholder="Enter rig description"
                            value={rigDescription}
                            onChange={(e) => setRigDescription(e.target.value)} />
                    </InputGroup>
                    <InputGroup controlId="formRigLatitude" className='mb-3'>
                        <InputGroup.Text>Rig Latitude</InputGroup.Text>
                        <Form.Control type="number"
                            placeholder="Enter rig latitude"
                            value={rigLatitude}
                            onChange={(e) => setRigLatitude(e.target.value)} />
                    </InputGroup>
                    <InputGroup controlId="formRigLongitude" className='mb-3'>
                        <InputGroup.Text>Rig Longitude</InputGroup.Text>
                        <Form.Control type="number"
                            placeholder="Enter rig longitude"
                            value={rigLongitude}
                            onChange={(e) => setRigLongitude(e.target.value)} />
                    </InputGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleCreateRig} disabled>
                        Create
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default ConnectionDb