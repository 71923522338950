import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { Modal, Button } from 'react-bootstrap';
import { deleteBd, insertTableBd } from '../../../config/functions';

export const ModalUserPerm = (
    {
        usuarioAll,
        setIsLoading,
        show,
        handleClose,
        taladrosUsuario,
        user
    }
) => {

    const usuario = usuarioAll.vr_usurio_usu

    const [tUsuario, setTUsuario]= useState(taladrosUsuario)

    const handleCheckboxChange = (e, tldro) => {
        const optionM = (e === true ? 'Agregar' : 'Eliminar')
        if (window.confirm(`¿Estás seguro de ${optionM} los permisos para el taladro: ${tldro.vr_tldro_nom} al usuario: ${usuario}?`)) {
            updateChecks(tldro.nb_tldro_idn, e);
        }
    }

    const updateChecks = async (nb_tldro_idn, isChecked) => {

        let arregloTemporal = []
        tUsuario.forEach(tu => {
            let datoTemporal = { ...tu, check: tu.check ? tu.check : 0 }
            if (tu.nb_tldro_idn === nb_tldro_idn) {
                datoTemporal.check = isChecked === true ? 1 : 0
            }
            arregloTemporal.push(datoTemporal)
        });

        setTUsuario(arregloTemporal)

        const fecha = new Date()
        const datosEnviar = {
            nb_tldro_idn,
            nb_usurio_idn: usuarioAll.nb_usurio_idn
        }
        const datosEliminar = nb_tldro_idn + ':' + usuarioAll.nb_usurio_idn

        if (isChecked === true) {
            datosEnviar.vr_adt_usureg = user
            datosEnviar.dt_adt_fchreg = fecha.toISOString()

            try {
                await insertTableBd('createriguser', datosEnviar)
                toast.success('Permiso guardado con éxito')
            } catch (error) {
                toast.warning('bd don\'t work');
            } finally {
                setIsLoading(false)
            }

        } else {

            try {
                const data = await deleteBd(datosEliminar, 'deleteriguser')
                toast.info(data);
            } catch (error) {
                toast.warning('bd don\'t work');
            } finally {
                setIsLoading(false)
            }

        }
    };

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Permisos de <strong>{usuario}</strong> x Taladro
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table className="table table-sm table-hover table-striped align-middle">
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">Taladro</th>
                                <th scope="col">Descipción</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tUsuario?.map(tldro => (
                                <tr key={tldro.nb_tldro_idn}>
                                    <td className='text-center'>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={tldro.check}
                                                id="flexCheckDefault"
                                                onChange={(e) => handleCheckboxChange(e.target.checked, tldro)} />
                                        </div>
                                    </td>
                                    <td>{tldro.vr_tldro_nom}</td>
                                    <td>{tldro.vr_tldro_des === 'TAladro no configurado, creado automáticamente por el sistemaaa'
                                        ? 'Sin Descripción'
                                        : tldro.vr_tldro_des}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary"
                        onClick={handleClose}> Close </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
