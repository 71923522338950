import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { Modal, Button } from 'react-bootstrap';
import { getVariables, updateTableBd } from '../../../config/functions';

export const ModalVarEdit = (
    {
        variableAll,
        setIsLoading,
        show,
        handleClose,
        setVariables,
        user
    }
) => {

    const idVar = variableAll.nb_var_idn

    const [variable, setVariable] = useState('')
    const [descripcion, setDescripcion] = useState('')
    const [unidad, setUnidad] = useState('')

    useEffect(() => {
        if (show) {
            setVariable(variableAll.vr_var_nom)
            setDescripcion(variableAll.vr_var_des)
            setUnidad(variableAll.vr_var_umd)
        }
    }, [show])

    const updateVar = async () => {
        if (variable !== '' && unidad !== '') {
            if (window.confirm('Esta seguro de continuar con la edición?')) {
                setIsLoading(true)
                try {
                    const fecha = new Date()
                    const dataSend = {
                        vr_var_nom: variable,
                        nb_var_idn: parseInt(idVar),
                        vr_var_des: descripcion,
                        vr_var_umd: unidad,
                        vr_adt_usureg: user,
                        dt_adt_fchreg: fecha.toISOString()
                    }
                    const update = await updateTableBd('updatevar', dataSend)
                    toast.success(update);
                    getVariables(setVariables);
                    handleClose()
                } catch (error) {
                    console.error('Error al realizar la solicitud:', error);
                } finally {
                    setIsLoading(false)
                }
            }
        } else {
            toast.warning('Debe diligenciar Nombre y unidad de medida')
        }
    }

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Edición de variable: {variableAll.vr_var_tag}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row g-3 justify-content-center">
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1">Nombre</span>
                            <input type="text"
                                className="form-control"
                                placeholder="Nombre"
                                aria-label="Nombre"
                                value={variable}
                                onChange={(e) => setVariable(e.target.value)} />
                        </div>
                    </div>

                    <div className="row g-3 justify-content-center">
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1">Descripción</span>
                            <textarea
                                type="text"
                                className="form-control"
                                placeholder="Descripción"
                                value={descripcion}
                                onChange={(e) => setDescripcion(e.target.value)} />
                        </div>
                    </div>

                    <div className="row g-3 justify-content-center">
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1">Unidad de Medida</span>
                            <input type="text"
                                className="form-control"
                                placeholder="UDM"
                                value={unidad}
                                onChange={(e) => setUnidad(e.target.value)} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}> Close </Button>
                    <Button variant="primary" onClick={() => updateVar()}>
                        Save changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
