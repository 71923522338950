

const notificaciones = 'Durante el uso del componente, recibirás notificaciones y alertas que indicarán el estado de las operaciones realizadas, como confirmaciones de acciones exitosas o advertencias sobre posibles incongruencias.'

const variables = {
    "path": "variables",
    "component": "Variables",
    "name": "Variables",
    "introduccion": "Este componente maneja la visualización y la interacción con las variables. Incluye filtrado, paginación, y la posibilidad de editar variables.",
    "navegacion": "Al editar una variable se abre un Modal para modificar Nombre, descripción y unidad de medida de la variable seleccionada, en la parte inferior se encuentra la paginación.",
    "informacion": "La información mostrada en este componente incluye detalles de las variables, como el nombre, la unidad de medida y la descripción. Esta información se muestra en una tabla, y los detalles específicos se pueden expandir y contraer.",
    "opciones": [
        { 
            "opcion": "Filtrar", 
            "detalle": "Permite al usuario filtrar las variables mostradas.", 
            "boton": "fa-solid fa-search fa-rotate-90" 
        },
        { 
            "opcion": "Editar", 
            "detalle": "Permite al usuario editar una variable específica.", 
            "boton": 'fa-solid fa-pencil' 
        },
        { 
            "opcion": "Paginación", 
            "detalle": "Permite al usuario navegar a través de las páginas de variables.", 
            "boton": "No hay un botón específico para esto en el componente, pero se maneja a través del componente Pagination." 
        },
    ],
    "notificaciones": "Este componente no parece manejar notificaciones directamente. Sin embargo, el estado de carga se maneja a través del componente LoaderES, que podría considerarse una forma de notificación."
}

const users = {
    "path": "users",
    "component": "Users",
    "name": "Users",
    "introduccion": "Este componente maneja la visualización y la interacción con los usuarios. Incluye filtrado, paginación, y la posibilidad de editar usuarios, cambiar contraseña y asignar permisos de acceso a los taladros.",
    "navegacion": "Al editar un usuario se abre un Modal con los atributos del usuario seleccionado, igualmente cuando se quiere asignar un permisos de acceso a un taladro en la parte inferior se encuentra la paginación.",
    "informacion": "La información mostrada en este componente incluye detalles de los usuarios, como el nombre, el correo electrónico y el estado. Esta información se muestra en una tabla, y los detalles específicos se pueden expandir y contraer.",
    "opciones": [
        { 
            "opcion": "Filtrar", 
            "detalle": "Permite al usuario filtrar los usuarios mostrados.", 
            "boton": "fa-solid fa-search fa-rotate-90" 
        },
        { 
            "opcion": "Editar", 
            "detalle": "Permite al usuario editar un usuario específico.", 
            "boton": "fa-solid fa-pencil" 
        },
        { 
            "opcion": "Cambiar contraseña", 
            "detalle": "Permite al usuario solicitar un cambio de contraseña para un usuario específico, es opción obliga al usuario a cambiar su contraseña en el próximo inicio de sesión.", 
            "boton": 'fa-solid fa-key'
        },
        { 
            "opcion": "Ver permisos", 
            "detalle": "Permite asignar o eliminar los permisos de un usuario específico para la visualización de taladros.", 
            "boton": 'fa-solid fa-list-check'
        },
        { 
            "opcion": "Paginación", 
            "detalle": "Permite al usuario navegar a través de las páginas de usuarios.", 
            "boton": "" 
        },
        { 
            "opcion": "Color ocre", 
            "detalle": "Evidencia que el usuario no ha ingresado al sistema o tiene un cambio de contraseña perndiente.", 
            "boton": "" ,
            "estilo": "table-warning"
        },
        { 
            "opcion": "Color rojo", 
            "detalle": "Evidencia que el usuario está en estado inactivo.", 
            "boton": "" ,
            "estilo": "table-danger"
        }
    ],
    "notificaciones": "Este componente maneja notificaciones a través de la biblioteca 'react-toastify'. Las notificaciones se muestran cuando ocurre un error al interactuar con la base de datos, o cuando se realiza una acción exitosa."
}

const plantillas = {
    path: 'plantilla',
    component: 'plantillas',
    name: 'plantillas',
    introduccion: 'Este componente maneja la visualización y la interacción con las plantillas. Incluye filtrado, paginación, y la posibilidad de editar, adicionar y eliminar plantillas existentes, teniendo en cuenta que una plantilla puede ser configurada en las variables de la cuadricula de la izquierda, variables para gráficas y variables para LiveData.',
    navegacion: 'Se debe seleccionar o crear una plantilla para poder visualizar las variables asociadas a la misma, y poder editarlas a conveniencia, en la parte inferior se encuentra la paginación.',
    informacion: 'la información mostrada en este componente incluye detalles de las plantillas, como el nombre, la descripción y las variables asociadas. Esta información se muestra en una tabla, y los detalles específicos se pueden expandir y contraer cambiando el tipo de plantilla en el selector principal',
    opciones: [
        { opcion: 'Adicionar Plantilla', detalle: 'Habilita un modal para la creación de una nueva plantilla', boton: 'fa-solid fa-plus btn btn-primary' },
        { opcion: 'Editar Plantilla', detalle: 'Habilita un modal para la edicion de una plantilla existente', boton: 'fa-solid fa-pencil btn btn-info' },
        { opcion: 'Eliminar Plantilla', detalle: 'Habilita un mensaje de confirmación para eliminar una plantilla existente', boton: 'fa-solid fa-trash-can btn btn-danger' }
    ] ,
    notificaciones
}

export const documentation = [
    variables,
    users,
    plantillas
];

/* {
    path: '',
    component: '',
    name: '',
    introduccion: '',
    navegacion: '',
    informacion: '',
    opciones: [
        { opcion: '', detalle: '', boton: '' },
        { opcion: '', detalle: '', boton: '' },
        { opcion: '', detalle: '', boton: '' }
    ] ,
    notificaciones: ''
} */