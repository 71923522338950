import React, { useState, useEffect } from 'react';
import { ordenar, ordenarArreglo } from '../../../config/functions';
import { LoaderES } from '../../LoaderES';
import { Filtrar } from '../../Filtrar';
import { Pagination } from '../../Pagination';
import { ModalVarEdit } from './ModalVarEdit';
import { Collapse } from 'react-bootstrap';

const Variables = ({ broken, collapsed, mobile, variables, setVariables, user, colors }) => {

  const [documentosFiltrados, setDocumentosFiltrados] = useState([]);
  const [variable, setVariable] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(null);
  const [registrosPorPagina, setRegistrosPorPagina] = useState(15);
  const [paginaActual, setPaginaActual] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(0)
  const [registrosPaginaActual, setRegistrosPaginaActual] = useState([])

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);

  //---------------------------------------------

  useEffect(() => { setDocumentosFiltrados(variables) }, [variables])

  //---------------------------------------------

  useEffect(() => {
    setTotalPaginas(Math.ceil(documentosFiltrados.length / registrosPorPagina))
  }, [documentosFiltrados, registrosPorPagina])

  useEffect(() => {
    const inicio = (paginaActual - 1) * parseInt(registrosPorPagina)
    const fin = inicio + parseInt(registrosPorPagina)
    setRegistrosPaginaActual(documentosFiltrados.slice(inicio, fin))
  }, [paginaActual, totalPaginas])

  //---------------------------------------------

  return (
    <>
      {(variables.length === 0 || isLoading)
        ? <LoaderES />
        : <>
          <Filtrar
            documentos={variables}
            setDocumentosFiltrados={setDocumentosFiltrados}
            setRegistrosFiltrados={setRegistrosPaginaActual}
            registrosPorPagina={registrosPorPagina} 
            colors={colors}/>

          {mobile
            ? <>
              {registrosPaginaActual?.map((vrbl, index) => (
                <div key={vrbl.nb_var_idn}>
                  <table className="table table-sm table-hover table-striped mb-0">
                    <thead style={{ fontSize: '14px', color: colors.colorFont }}>
                      <tr>
                        <th style={{ width: '50px', resize: 'none' }}>
                          <button className="btn  fa-solid fa-chevron-down btn-sm"
                            style={{ color: colors.colorFont || '#000000' }}
                            onClick={() => setOpen(open === index ? null : index)}
                            aria-controls={"collapse-item-" + index}
                            aria-expanded={open === index}
                          />
                        </th>
                        <th style={{ width: '150px', resize: 'none' }}>{vrbl.vr_var_nom}</th>
                        <th style={{ width: '50px', resize: 'none' }} className='text-end'>
                          <button className='btn btn-sm me-2'
                            style={{ color: colors.colorFont || '#000000' }}
                            onClick={() => {
                              setVariable(vrbl)
                              setShowModal(true)
                            }}>
                            <i className="fa-solid fa-pencil" />
                          </button>
                        </th>
                      </tr>
                    </thead>
                  </table>


                  <Collapse in={open === index}>
                    <div id={"collapse-item-" + index} className="mt-2">
                      <div className="card card-body" style={{ backgroundColor: colors.colorBg || '#FFFFFFF', borderColor: colors.colorFont || '#000000' }}>
                        <table className="table table-sm" style={{ color: colors.colorFont || '#000000' }}>
                          <tbody style={{ fontSize: '12px' }}>

                            <tr>
                              <td>Variable: </td>
                              <td>{vrbl.vr_var_nom}</td>
                            </tr>

                            <tr>
                              <td>UM:</td>
                              <td>{vrbl.vr_var_umd}</td>
                            </tr>

                            <tr>
                              <td>Descripción:</td>
                              <td style={{ minWidth: '60px', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', resize: 'none' }}>
                                <span className="truncate-text">
                                  {vrbl.vr_var_des.length > 50 ? vrbl.vr_var_des.slice(0, 50) + '...' : vrbl.vr_var_des}
                                </span>
                              </td>
                            </tr>

                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Collapse>

                </div>
              ))}
            </>
            : <div className="table-responsive mt-3">
              <table className="table table-hover table-sm align-middle table-bordered" id='myTable'
                style={{ color: colors.colorFont || '#000000' }}>
                <thead>
                  <tr>
                    <th scope="col" ></th>
                    <th scope="col" >TAG &nbsp;&nbsp;
                      <i className="fa-solid fa-arrow-down-1-9"
                        onClick={() => ordenarArreglo('vr_var_tag', variables, setVariables)}
                        style={{ cursor: "pointer", color: colors.colorFont || '#000000' }} />
                    </th>
                    <th scope="col" >Variable &nbsp;&nbsp;
                      <i className="fa-solid fa-arrow-down-a-z"
                        onClick={() => ordenarArreglo('vr_var_nom', variables, setVariables)}
                        /* onClick={() => ordenar(2)} */
                        style={{ cursor: "pointer" }} />
                    </th>
                    <th scope="col" >Unidad de Medida &nbsp;&nbsp;
                      <i className="fa-solid fa-arrow-down-a-z"
                        onClick={() => ordenarArreglo('vr_var_umd', variables, setVariables)}
                        /* onClick={() => ordenar(3)} */
                        style={{ cursor: "pointer" }} />
                    </th>
                    <th scope="col" >Descripción &nbsp;&nbsp;
                      <i className="fa-solid fa-arrow-down-a-z"
                        onClick={() => ordenarArreglo('vr_var_des', variables, setVariables)}
                        /* onClick={() => ordenar(4)} */
                        style={{ cursor: "pointer" }} />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {variables && variables.length > 0 ? registrosPaginaActual.map(vrbl => (
                    <tr key={vrbl.nb_var_idn} style={{ fontSize: '11px' }}>
                      <td>
                        <button className='btn btn-sm fa-solid fa-pencil'
                          onClick={() => {
                            setVariable(vrbl)
                            setShowModal(true)
                          }}
                          style={{ color: colors.colorFont || '#000000' }}
                        >
                        </button>
                      </td>
                      <td scope="row">{vrbl.vr_var_tag}</td>
                      <td>{vrbl.vr_var_nom}</td>
                      <td>{vrbl.vr_var_umd}</td>
                      <td>{vrbl.vr_var_des}</td>
                    </tr>
                  )) : null}
                </tbody>
              </table>
            </div>
          }

          <Pagination
            paginaActual={paginaActual}
            totalPaginas={totalPaginas}
            setPaginaActual={setPaginaActual}
            registrosPorPagina={registrosPorPagina}
            setRegistrosPorPagina={setRegistrosPorPagina}
            documentosFiltrados={documentosFiltrados}
            broken={broken}
            collapsed={collapsed}
            mobile={mobile}
            colors={colors}
          />

          <ModalVarEdit
            variableAll={variable}
            show={showModal}
            setIsLoading={setIsLoading}
            handleClose={handleCloseModal}
            setVariables={setVariables}
            user={user}
          />

        </>
      }
    </>
  )
}

export default Variables
