import React, { useState, useEffect, useRef } from "react";
import { compararFechas, deleteBd, getTableByIdBd, insertTableBd, updateTableBd } from "../../config/functions";
import { Chart } from "./Chart";
import { toast } from "react-toastify";
import { GrillaIzq } from "./GrillaIzq";
import { ModalVarDown } from "../ModalVarDown";
import DatePicker from "react-datepicker";
import moment from 'moment';
import Highcharts from "highcharts/highstock";
import ModalPlantillaNew from "../ModalPlantillaNew";
import "react-datepicker/dist/react-datepicker.css";

const LiveGraphs = ({ rig, setRig, mobile, variables, uid, user, horizontal, setCollapsed, setToggled, colors }) => {

  //---------------------------------------------------------------------------------------------------
  const tiempoIntervalo = 5000
  //---------------------------------------------------------------------------------------------------
  const [tiempoNumero, setTiempoNumero] = useState(4)
  const [tiempoTipo, setTiempoTipo] = useState('h')
  //---------------------------------------------------------------------------------------------------
  const [busquedaFechaActiva, setBusquedaFechaActiva] = useState(false)
  const [enVivo, setEnVivo] = useState(false)
  //---------------------------------------------------------------------------------------------------
  const [varsDown, setVarsDown] = useState([])
  //----------------------------------------------------------------------------------------------------
  const [fechaInicialGrafica, setFechaInicialGrafica] = useState(null)
  const [fechaFinalGrafica, setFechaFinalGrafica] = useState(null)
  const [fechaInicialTx, setFechaInicialTx] = useState(null)
  const [fechaFinalTx, setFechaFinaltx] = useState(null)
  const [fechaTemporal, setFechaTemporal] = useState('');
  //----------------------------------------------------------------------------------------------------
  const [medidasGraficas, setMedidasGraficas] = useState([])
  const [medidasGraficasProcesadas, setMedidasGraficasProcesadas] = useState([])
  const [datosInferiores, setDatosInferiores] = useState([])
  const [datosNuevos, setDatosNuevos] = useState([])

  const [allLiveData, setAllLiveData] = useState([])
  //----------------------------------------------------------------------------------------------------
  const [nb, setNb] = useState('')
  const nbRef = useRef('')
  const intervalRef = useRef(null);
  //---------------------------------------------------------------
  const [showDataLeft, setShowDataLeft] = useState(true)
  const [tooltipEnabled, setToolTipEnabled] = useState(false)
  const [tooltipEnabledShared, setToolTipEnabledShared] = useState(false)
  const [graficoActivo, setGraficoActivo] = useState('chart1');
  //----------------------------------------------------------------
  const [ejeAutomatico, setEjeAutomatico] = useState(false)
  const [showComentarios, setShowComentarios] = useState(false)
  //----------------------------------------------------------------
  const [modalDownVar, setModalDownVar] = useState({})
  const [posInferior, setPosInferior] = useState(100)
  const [showModalVarDown, setShowModalVarDown] = useState(false);
  const handleCloseModalVarDown = () => setShowModalVarDown(false);
  const [seriesMin, setSeriesMin] = useState(0)
  const [seriesMax, setSeriesMax] = useState(100)
  //----------------------------------------------------------------
  const [showModalPlantilla, setShowModalPlantilla] = useState(false);
  const handleOpenModalPlantilla = () => setShowModalPlantilla(true);
  const handleCloseModalPlantilla = () => setShowModalPlantilla(false);
  //----------------------------------------------------------------
  const [isLoading, setIsLoading] = useState(false)
  //----------------------------------------------------------------
  const [comentarios, setComentarios] = useState([])
  const [comentariosProcesados, setComentariosProcesados] = useState([])
  //----------------------------------------------------------------
  const [colorSerie1, setColorSerie1] = useState('blue')
  const [colorSerie2, setColorSerie2] = useState('black')
  const [sinColor, setSinColor] = useState(0)
  //----------------------------------------------------------------
  const [usaDefault, setUsaDefault] = useState(false)
  //----------------------------------------------------------------

  useEffect(() => {
    setNb('')
    getSeriesColor(rig)
    return () => { if (intervalRef.current) clearInterval(intervalRef.current) }
  }, [])

  useEffect(() => {
    setNb('')
    if (intervalRef.current) clearInterval(intervalRef.current)
    setFechaInicialTx(localStorage.getItem("fInicial") || null)
    setFechaInicialGrafica(null)
    setFechaFinalGrafica(null)
    setFechaFinaltx(null)
    setMedidasGraficas([])
    setMedidasGraficasProcesadas([])
    setDatosInferiores([])
    setDatosNuevos([])
    setBusquedaFechaActiva(false)
    if (rig === 0) return
    funcionCambioRig()
  }, [rig])

  useEffect(() => { nbRef.current = nb }, [nb])

  useEffect(() => {
    //console.log('medidasGraficas', medidasGraficas);
    try {
      if (!medidasGraficas.length || medidasGraficas.length === 0) return;
      const newTemporals = Array.from({ length: 12 }, () => []);
      medidasGraficas?.forEach((d, index) => {
        if (index <= 169999) {
          const parsed = parseFloat(d.nb_mda_val);
          const dato = isNaN(parsed) ? 0 : parseFloat(parsed.toFixed(2));
          const fecha = new Date(d.dt_mda_fch).getTime() //- 18000000; // Ajuste de 5 horas
          varsDown.forEach((varDown, i) => {
            if (parseInt(d.nb_var_idn) === parseInt(varDown)) {
              newTemporals[i] = newTemporals[i] || [];
              newTemporals[i].push([fecha, dato]);
            }
          });
        }
      });
      setMedidasGraficasProcesadas(newTemporals)
    } catch (error) {
      //console.error('Error en el procesamiento de las medidas');
      setTiempoNumero(2)
    }
  }, [medidasGraficas])

  useEffect(() => {
    if (comentarios.length > 0) setComentariosProcesados(transformarComentariosEnPuntos(comentarios))
  }, [comentarios])

  useEffect(() => {

    const container = document.getElementById('container');
    if (!container || !tooltipEnabledShared) return;

    const handleEvent = (e) => {
      let chart, point, i, event;

      for (i = 0; i < Highcharts.charts.length; i++) {
        chart = Highcharts.charts[i];
        if (chart && chart.pointer) {
          event = chart.pointer.normalize(e);
          point = chart.series[0].searchPoint(event, true);

          if (point) {
            point.highlight(e);
          }
        }
      }
    };

    Highcharts.Point.prototype.highlight = function (event) {
      event = this.series.chart.pointer.normalize(event);

      if (this && !isNaN(this.plotX) && !isNaN(this.plotY)) {
        this.onMouseOver(); // Muestra el marcador de hover
      }

      const allSeries = [];
      const allPoints = [];

      Highcharts.charts.forEach(function (chart) {
        if (chart) {
          chart.series.forEach(function (series) {
            if (series.visible && series.data.length > 0) { // Verifica si la serie es visible y tiene datos
              const point = series.searchPoint(event, true);
              if (point && !isNaN(point.plotX) && !isNaN(point.plotY)) {
                allPoints.push(point);
                allSeries.push(series);
              }
            }
          });
          if (this && this.series && !isNaN(this.plotX) && !isNaN(this.plotY)) {
            chart.xAxis[0].drawCrosshair(event, this); // Verifica también aquí antes de dibujar el crosshair
          }
        }
      }, this);
      if (allPoints.length > 0) {
        this.series.chart.tooltip.refresh(allSeries.map(function (series, i) {
          return [allPoints[i], series];
        }), { followPointer: true, hideDelay: 0, useHTML: true, animation: false });
      }
    };

    // Añadir manejadores de eventos
    if (tooltipEnabled) {
      ['mousemove', 'touchmove', 'touchstart'].forEach((eventType) => {
        container.addEventListener(eventType, handleEvent);
      });
    }

    // Función de limpieza para eliminar manejadores de eventos
    return () => {
      ['mousemove', 'touchmove', 'touchstart'].forEach((eventType) => {
        container.removeEventListener(eventType, handleEvent);
      });
    };

  }, [tooltipEnabledShared])

  useEffect(() => { iniciarIntervalo() }, [varsDown])
  useEffect(() => { if (rig) getMedidasGraficas(rig, varsDown) }, [tiempoNumero, tiempoTipo])
  useEffect(() => { if (rig) getMedidasGraficas(rig, varsDown) }, [tiempoNumero, tiempoTipo])
  useEffect(() => { if (busquedaFechaActiva) setMedidasGraficas([]) }, [busquedaFechaActiva])
  useEffect(() => { if (busquedaFechaActiva) setMedidasGraficas([]) }, [busquedaFechaActiva])

  //--------------------------------------------------------------

  const funcionCambioRig = async () => {
    const varsDownTempo = await getDataGraphPlantilla(rig)
    setVarsDown(varsDownTempo)
    await getMedidasGraficas(rig, varsDownTempo)
    await renderLiveData(rig)
    await getSeriesColor(rig)
    iniciarIntervalo()
  }

  //--------------------------------------------------------------

  const getSeriesColor = async (rigId) => {
    try {
      const rigUid = rigId + ':' + uid
      const data = await getTableByIdBd(rigUid, 'getseriescolor')
      if (data.length > 0) {
        setColorSerie1(data[0].nb_serie_1)
        setColorSerie2(data[0].nb_serie_2)
        setSinColor(data[0].nb_colores_idn)
      } else {
        setColorSerie1('black')
        setColorSerie2('blue')
        setSinColor(0)
      }
    } catch (error) {
      toast.warning('bd Color don\'t work');
    }
  }

  //--------------------------------------------------------------

  const getDataGraphPlantilla = async (rigId) => {
    const dataSend = rigId + ':' + user
    const data = await getTableByIdBd(dataSend, 'getvarsalarmgraph')

    let d = []

    if (data.length > 0) {
      d = [...data]
      setUsaDefault(false)
    } else {
      const dataDefault = await getTableByIdBd(dataSend, 'getplantilladefaultgrapgh')
      d = [...dataDefault]
      setUsaDefault(true)
    }

    const ordenVariables = Array.from({ length: 12 }, (_, index) => {
      const foundItem = d.find(di => index === parseInt(di.nb_tldvrb_ord));
      return foundItem ? foundItem.nb_var_idn : '0';
    });

    const variablesDown = Array.from({ length: 12 }, (_, index) => {
      return d.find(di => index === parseInt(di.nb_tldvrb_ord)) || '';
    });

    setDatosInferiores(variablesDown);
    return (ordenVariables)

  }

  const iniciarIntervalo = () => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => { renderLiveData(rig) }, tiempoIntervalo)
  }

  //--------------------------------------------------------------------

  const renderLiveData = async (rigid) => {
    try {
      const dataSend = `${rig}:${uid}`;
      const data = await getTableByIdBd(dataSend, 'getvarsalarmgraphact')

      if (data.length === 0) {
        setEnVivo(false)
        setNb('')
        return;
      }

      const nuevaFecha = data[0]?.dt_mda_fch;
      const ultimaFecha = new Date(nuevaFecha)


      if (nbRef.current !== ultimaFecha.getTime()) {
        setEnVivo(true)
        nbRef.current = ultimaFecha.getTime()
      } else {
        setEnVivo(false)
      }

      setFechaFinalGrafica(ultimaFecha)
      await getMedidasGraficasNuevas(ultimaFecha, rigid)

    } catch (error) {
      console.error('renderLiveData'.error);
    }
  };

  //--------------------------------------------------------------------

  const getMedidasGraficas = async (rigId, varsD) => {

    const dataSend1 = rigId + ':' + uid;
    const dataLastDate = await getTableByIdBd(dataSend1, 'getvarsalarmgraphact')

    if (dataLastDate.length === 0) return

    const ultimaFecha = new Date(dataLastDate[0].dt_mda_fch) + 0

    if (!varsD?.length || !ultimaFecha) return;

    const fechaF = moment.utc(ultimaFecha).add(5, 'h')//.format('yyyy-MM-DDTHH:mm')
    const fechaI = moment.utc(fechaF).subtract(parseInt(tiempoNumero), tiempoTipo)//.format('yyyy-MM-DDTHH:mm')


    const dataSend = `${rigId};${varsD};${fechaF};${fechaI}`;
    const dataSendComments = `${rigId};${fechaF};${fechaI}`;

    setIsLoading(true)
    try {
      const data = await getTableByIdBd(dataSend, 'getvarstldrofecha')
      const dataComments = await getTableByIdBd(dataSendComments, 'getcommentsperiod')
      if (dataComments.length > 0) setComentarios(dataComments)
      setFechaInicialGrafica(fechaI)
      setFechaFinalGrafica(fechaF)

      if (data.length >= 1) setMedidasGraficas(data)
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false)
    }

  }

  const getMedidasGraficasNuevas = async (fechal, rigId) => {

    if (busquedaFechaActiva === 1) return

    const fechaI = moment.utc(fechal).format('yyyy-MM-DDTHH:mm:ss')
    const fechaF = moment.utc(fechal).subtract(5, 's').format('yyyy-MM-DDTHH:mm:ss')
    const dataSendComments = `${rigId};${fechaI};${fechaF}`;

    if (Object.keys(varsDown).length > 0) {

      const data = await getTableByIdBd(rigId, 'getlivedatagraph')
      const dataComments = await getTableByIdBd(dataSendComments, 'getcommentsperiod')

      if (JSON.stringify(data) === JSON.stringify(datosNuevos) || data.length === 0) {
        setEnVivo(false)
        return;
      }

      let dataOrdenada = []
      varsDown.map((varIdn, index) => {
        const tempo = data.find(d => parseInt(d.nb_var_idn) === parseInt(varIdn))
        if (tempo) {
          dataOrdenada[index] = tempo
        } else {
          const dato = {
            nb_tldro_idn: data[0].nb_tldro_idn,
            nb_var_idn: varIdn,
            dt_mda_fch: data[0].dt_mda_fch,
            nb_mda_prof: data[0].nb_mda_prof,
            nb_mda_val: "0",
          }
          dataOrdenada[index] = dato
        }
      })
      setAllLiveData(data)
      setFechaFinaltx(fechal)
      setDatosNuevos(dataOrdenada)
      if (dataComments.length > 0) setComentarios(dataComments)
    } else {
      const varsDownTempo = await getDataGraphPlantilla(rigId)
      setVarsDown(varsDownTempo)
    }
  }

  //------------------------------------------------------------------------

  const moveGraphs = async (dir) => {
    if (!(fechaFinalGrafica && fechaInicialGrafica)) {
      toast.info('No hay datos para navegación')
      return
    }
    switch (dir) {
      case 'back':
        getMedidasCount(-1)
        break;
      case 'forward':
        getMedidasCount(1)
        break;
      case 'toDate':
        setBusquedaFechaActiva(false)
        setFechaTemporal('')
        const varsDownTempo = await getDataGraphPlantilla(rig)
        setVarsDown(varsDownTempo)
        getMedidasGraficas(rig, varsDownTempo)
        break
    }
  }

  const getMedidasCount = async (contador) => {

    //console.log(medidasGraficas);
    //if (medidasGraficas.length === 0) return

    setIsLoading(true)

    let dateStart, dateEnd

    if (contador < 0) {
      dateEnd = fechaInicialGrafica
      dateStart = moment.utc(dateEnd).subtract(parseInt(tiempoNumero), tiempoTipo).format('yyyy-MM-DDTHH:mm:ss');
    } else {
      dateStart = fechaFinalGrafica
      dateEnd = moment.utc(dateStart).add(parseInt(tiempoNumero), tiempoTipo).format('yyyy-MM-DDTHH:mm:ss')
    }

    const dataSend = `${rig};${varsDown};${dateEnd};${dateStart}`;
    //console.log(dataSend);
    const dataSendComments = `${rig};${dateEnd};${dateStart}`;

    try {
      const data = await getTableByIdBd(dataSend, 'getvarstldrofecha');
      const dataComments = await getTableByIdBd(dataSendComments, 'getcommentsperiod')

      if (data.length > 12) {

        const nuevaFechaInicial = compararFechas(fechaInicialGrafica, dateStart, 'inicial')
        const nuevaFechaFinal = compararFechas(fechaFinalGrafica, dateEnd, 'final')

        setFechaInicialGrafica(moment.utc(nuevaFechaInicial).format('yyyy-MM-DDTHH:mm:ss'))
        setFechaFinalGrafica(moment.utc(nuevaFechaFinal).format('yyyy-MM-DDTHH:mm:ss'))

        const dataOld = [...medidasGraficas]
        const dataBack = [...data, ...dataOld]
        const dataForward = [...dataOld, ...data]

        setMedidasGraficas(contador < 0 ? dataBack : dataForward)
        if (dataComments.length > 0) setComentarios(dataComments)

      } else {
        toast('no se encuentran datos adicionales')
      }
    } catch (error) {
      console.error("Error al obtener los datos:", error);
    } finally {
      setIsLoading(false)
    }
  };

  //------------------------------------------------------------------------

  const findDate = async () => {
    const findDateStart = moment.utc(fechaTemporal).subtract(5, 'h').format('yyyy-MM-DDTHH:mm')//add(5, 'h').
    const findDateEnd = moment.utc(findDateStart).add(parseInt(tiempoNumero), tiempoTipo).format('yyyy-MM-DDTHH:mm')
    const dataSend = rig + ';' + varsDown + ';' + findDateEnd + ';' + findDateStart
    const dataSendComments = `${rig};${findDateEnd};${findDateStart}`;
    if (varsDown) {
      setIsLoading(true)
      try {
        const data = await getTableByIdBd(dataSend, 'getvarstldrofecha');
        const dataComments = await getTableByIdBd(dataSendComments, 'getcommentsperiod')
        if (data.length > 12) {
          if (intervalRef.current) clearInterval(intervalRef.current);
          setBusquedaFechaActiva(true)
          const nuevaFechaInicial = findDateStart
          const nuevaFechaFinal = findDateEnd
          setFechaInicialGrafica(nuevaFechaInicial)
          setFechaFinalGrafica(nuevaFechaFinal)
          setMedidasGraficas(data)
          if (dataComments.length > 0) setComentarios(dataComments)
        } else {
          toast.warning('No se encontraron datos para la fecha y rango buscado')
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false)
      }
    }
  }

  //------------------------------------------------------------------------

  const addVarMatriz = async (ld, pos) => {
    try {
      const fecha = new Date()
      const dataSend = {
        nb_tldro_idn: parseInt(rig),
        nb_var_idn: parseInt(ld.nb_var_idn),
        vr_adt_usureg: user,
        dt_adt_fchreg: fecha.toISOString(),
        nb_tldvrb_ord: parseInt(pos),
        nb_tldvrb_min: parseInt(seriesMin),
        nb_tldvrb_max: parseInt(seriesMax),
      }
      const data = await insertTableBd('createvarmatrizgraph', dataSend);
      addLine(data, pos)
    } catch (error) {
      toast.warning('bd don\'t work');
    }
  }

  const updateVarMatriz = async (ld, pos) => {
    const fecha = new Date()
    const dataSend = {
      nb_tldvrb_idn: parseInt(datosInferiores[pos].nb_tldvrb_idn),
      nb_var_idn: parseInt(ld.nb_var_idn),
      nb_tldvrb_min: parseInt(seriesMin),
      nb_tldvrb_max: parseInt(seriesMax),
      vr_adt_usureg: user,
      dt_adt_fchreg: fecha.toISOString()
    }
    const data = await updateTableBd('updatevarmatrizgraph', dataSend)
    addLine(data, pos)
  }

  const addLine = async (datosVariable, posi) => {

    const datosInferioresTemporal = [...datosInferiores]
    const varsDownTemporal = !varsDown || varsDown.length === 0 ? [] : [...varsDown]
    const variableEncontrada = variables?.find(var_i => var_i.nb_var_idn === datosVariable.nb_var_idn);

    if (variableEncontrada) {
      datosInferioresTemporal[posi] = {
        ...datosVariable,
        ...variableEncontrada,
        nb_mda_val: 0,
        nb_mda_prof: 0
      };

      varsDownTemporal[posi] = datosVariable.nb_var_idn;

      setVarsDown(varsDownTemporal);
      setDatosInferiores(datosInferioresTemporal);

      await getMedidasUnaGrafica(datosVariable.nb_var_idn);
      toast.success("Se adiciona variable");
    } else {
      toast.error("Variable no encontrada.");
    }
  }

  const getMedidasUnaGrafica = async (medida) => {

    const fechaI = moment.utc(fechaInicialGrafica).format('yyyy-MM-DDTHH:mm')
    const fechaF = moment.utc(fechaFinalGrafica).format('yyyy-MM-DDTHH:mm')
    const dataSend = `${rig};${medida};${fechaF};${fechaI}`;

    if (!medida || !fechaInicialGrafica) return;

    const data = await getTableByIdBd(dataSend, 'getvarstldrofecha')
    const tempo = [...medidasGraficas, ...data]
    setMedidasGraficas(tempo)
  }

  const deleteVarMatriz = async (id, posi) => {
    if (window.confirm('Esta seguro de continuar?')) {
      await deleteBd(id, 'deletevarmatrizgraph')
      deleteLine(posi)
    }
  }

  const deleteLine = (posi) => {

    const datosInferioresTemporal = [...datosInferiores]
    const varsDownTemporal = [...varsDown]
    const medidasGraficasProcesadasTempo = [...medidasGraficasProcesadas]

    datosInferioresTemporal[posi] = ''
    varsDownTemporal[posi] = ''
    medidasGraficasProcesadasTempo[posi] = []

    setDatosInferiores(datosInferioresTemporal)
    setVarsDown(varsDownTemporal)
    setMedidasGraficasProcesadas(medidasGraficasProcesadasTempo)

    toast.success("Variable eliminada correctamente");
  }

  //------------------------------------------------------------------------

  const transformarComentariosEnPuntos = (comments) => {
    return comments.map(comentario => {
      return {
        x: new Date(comentario.dt_com_fch).getTime(), //+ 18000000,
        y: 0,
        comment: `${comentario.nb_com_val}`,
        user: `${comentario.vr_adt_usureg} `,
        dateInsert: `${moment.utc(comentario.dt_adt_fchreg).format(`DD-MM-YYYY HH:mm`)}`,
      };
    });
  };

  //------------------------------------------------------------------------

  const funcionActualizarVarsDown = async (rigId) => {
    const varsDownTempo = await getDataGraphPlantilla(rigId)
    setVarsDown(varsDownTempo)
  }

  //------------------------------------------------------------------------

  useEffect(() => {
    if (horizontal) {
      setCollapsed(true)
      setToggled(false)
      setShowDataLeft(false)
    }
  }, [horizontal])


  //------------------------------------------------------------------------

  return (
    <>
      {rig !== '' && (
        <>
          <div className="row">
            {/* Botones */}
            <div className={`col-sm-${horizontal ? '6' : '5'} mx-auto`}>
              <div className="input-group input-group-sm">

                <span className="input-group-text">
                  <i className={`fa-regular fa-object-group plantillaBtn ${isLoading ? 'disabled' : ''}`}
                    title="Plantillas"
                    disabled={isLoading}
                    onClick={handleOpenModalPlantilla} />
                </span>

                {/* Variables izq */}
                {!mobile && (
                  <span className="input-group-text">
                    <i className="fa-solid fa-ruler-vertical plantillaBtn"
                      title="Cuadrícula Izquierda"
                      onClick={() => setShowDataLeft(!showDataLeft)} />
                  </span>
                )}

                {/* eje Automatico */}
                {(!mobile || horizontal) && (
                  <span className="input-group-text">
                    <i className={`fa-solid fa-arrows-left-right${ejeAutomatico ? '-to-line ' : ''} plantillaBtn`}

                      onClick={() => setEjeAutomatico(!ejeAutomatico)}
                      title={!ejeAutomatico ? 'Eje Automatico' : 'Eje Manual'}
                      disabled={ejeAutomatico} />
                  </span>
                )}

                {/* tipo Tiempo */}
                <select className="form-select"
                  value={tiempoTipo}
                  style={{ /* maxWidth: mobile ? '' : '70px', */ fontSize: '11px' }}
                  disabled={medidasGraficas.length === 0 || isLoading || !fechaInicialTx || !fechaFinalTx}
                  onChange={(e) => setTiempoTipo(e.target.value)}>
                  <option value="m">Minutes</option>
                  <option value="h">Hours</option>
                </select>

                {/* Tiempo */}
                <select className="form-select"
                  value={tiempoNumero}
                  style={{ /* maxWidth: mobile ? '' : '70px', */ fontSize: '11px' }}
                  disabled={medidasGraficas.length === 0 || isLoading || !fechaInicialTx || !fechaFinalTx}
                  onChange={(e) => setTiempoNumero(e.target.value)}>
                  {tiempoTipo === 'm'
                    ? <>
                      <option value="1">1</option>
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="30">30</option>
                      <option value="45">45</option>
                    </>
                    : <>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="4">4</option>
                      {/* <option value="8">8</option> */}
                    </>
                  }
                </select>

                {/* Atras en el tiempo */}
                <span className="input-group-text">
                  <i className={`fa-solid fa-backward fa-rotate-90 plantillaBtn ${medidasGraficas.length === 0 || isLoading || !fechaInicialTx || !fechaFinalTx ? 'disabled' : ''} `}
                    title={`${tiempoNumero}${tiempoTipo} Atras`}
                    disabled={medidasGraficas.length === 0 || isLoading || !fechaInicialTx || !fechaFinalTx}
                    onClick={() => moveGraphs('back')} />
                </span>

                {/* ToolTip unica */}
                <span className="input-group-text">
                  <i className={`fa-${tooltipEnabled ? 'solid' : 'regular'} fa-message plantillaBtn ${isLoading ? 'disabled' : ''}`}
                    title="Tooltip"
                    disabled={isLoading}
                    onClick={() => {
                      setToolTipEnabled(!tooltipEnabled)
                      if (tooltipEnabledShared) setToolTipEnabledShared(false)
                    }} />
                </span>

                {/* ToolTip Compartida */}
                {(!mobile || horizontal) && tooltipEnabled && (
                  <span className="input-group-text">
                    <i className={`fa-solid fa-arrow-down-up-${tooltipEnabledShared ? 'lock' : 'across-line'} plantillaBtn ${isLoading ? 'disabled' : ''}`}
                      title="Shared Tooltip"
                      disabled={isLoading}
                      onClick={() => setToolTipEnabledShared(!tooltipEnabledShared)} />
                  </span>)
                }

                {/* Adelante en el tiempo */}
                {busquedaFechaActiva && (
                  <span className="input-group-text">
                    <i className={`fa-solid fa-backward fa-rotate-270 plantillaBtn ${isLoading ? 'disabled' : ''}`}
                      title="forward Time"
                      disabled={medidasGraficas.length === 0 || isLoading}
                      onClick={() => moveGraphs('forward')}
                    />
                  </span>
                )}

                {/* Ir a ultimo dato */}
                {busquedaFechaActiva && (
                  <span className="input-group-text">
                    <i className={`fa-solid fa-backward-step fa-rotate-270 plantillaBtn ${isLoading ? 'disabled' : ''}`}
                      disabled={medidasGraficas.length === 0 || isLoading}
                      title="to LastTime"
                      onClick={() => moveGraphs('toDate')} />
                  </span>
                )}

              </div>
            </div>

            {/* selector de fecha */}
            {(fechaInicialGrafica && fechaFinalGrafica) && (
              <>
                <div className={`col-sm-${mobile ? horizontal ? '6' : '8' : '4'}`}>
                  <div className="input-group input-group-sm">
                    <input type="text" className="form-control" aria-label="FechaInicial" disabled
                      style={{ /* maxWidth: mobile ? '' :  '153px', */ fontSize: '11px' }}
                      value={(moment.utc(fechaInicialGrafica).format(`${mobile ? 'DD-MM-YYYY' : 'DD MMM, yyyy'} HH:mm:ss a`))
                      }
                    />
                    <span className="input-group-text">
                      {busquedaFechaActiva
                        ? <i className="fa-solid fa-pause" style={{ fontSize: '11px' }} />
                        : <i className="fa-solid fa-circle" style={{ color: enVivo ? "green" : 'red', fontSize: '11px' }} />
                      }
                    </span>
                    <input type="text" className="form-control" aria-label="FechaInicial" disabled
                      style={{ /* maxWidth: mobile ? '' :  '153px', */ fontSize: '11px' }}
                      value={moment.utc(fechaFinalGrafica).format(`${mobile ? 'DD-MM-YYYY' : 'DD MMM, yyyy'} HH:mm:ss a`)} />
                    {/* value={moment.utc(fechaInicialGraficaValida).format(`${mobile || isSafari() ? 'DD-MM-YYYY' : 'DD MMM, yyyy'} HH:mm:ss a`)} /> */}
                  </div>
                </div>

                {!mobile && (
                  <div className="col-sm-3">
                    <div className="input-group input-group-sm">
                      <DatePicker

                        selected={fechaTemporal && new Date(fechaTemporal)}
                        className="form-control form-control-sm custom-datepicker"
                        showTimeSelect
                        onChange={(date) => setFechaTemporal(date)}
                        dateFormat={mobile ? "d-MM-yyyy H:mm:ss" : "d MMM, yyyy H:mm:ss"}
                        maxDate={new Date(fechaFinalTx)}
                        minDate={new Date(fechaInicialTx)}
                        disabled={isLoading || !fechaInicialTx || !fechaFinalTx}
                        placeholderText="Find Date"
                      />

                      <span className="input-group-text">
                        <i className={`fa-solid fa-magnifying-glass plantillaBtn ${(isLoading || fechaTemporal === '') ? 'disabled' : ''}`}
                          disabled={isLoading || fechaTemporal === ''}
                          title="Find Date"
                          onClick={() => findDate()} />
                      </span>
                      <span className="input-group-text">
                        <i className={` fa-solid fa-chevron-${showComentarios ? 'right' : 'left'} ${isLoading ? 'disabled' : ''} plantillaBtn`}
                          disabled={medidasGraficas.length === 0 || isLoading}
                          title={showComentarios ? 'Ocultar Comentarios' : 'Mostrar Comentarios'}
                          onClick={() => setShowComentarios(!showComentarios)} />
                      </span>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>

          {(mobile && !horizontal)
            ? <div className="row m-1 nav-fill" style={{ backgroundColor: colors.colorBg }}>
              < ul className="nav nav-tabs text-center p-0" id="myTab" >
                {Array.from({ length: 6 }).map((_, index) => (
                  <li className="nav-item"
                    style={{ fontSize: '12px', color: colors.colorBg }}
                    key={`tab-${index}`}
                    onClick={() => setGraficoActivo(`chart${index + 1}`)}>
                    <a href={`#chart${index + 1}`} style={{ color: colors.grColorFont }}
                      className={`nav-link ${graficoActivo === `chart${index + 1}` ? 'active' : ''}`}
                      data-bs-toggle="tab" >
                      <i className={index + 1 === 6 ? "fa-regular fa-comments" : "fa-solid fa-chart-line"}
                      />
                      {index + 1}
                    </a>
                  </li>
                ))}
              </ul >

              <div className="tab-content">

                {Array.from({ length: 6 }).map((_, index) => {
                  const baseIndex = index * 2;
                  const color1 = Object.keys(colors).length > 0 ? colors[`grColor${index + 1}_1`] : 'black'
                  const color2 = Object.keys(colors).length > 0 ? colors[`grColor${index + 1}_2`] : 'blue'

                  return (
                    <div
                      className={`tab-pane fade h-100 ${graficoActivo === `chart${index + 1}` ? 'active show' : ''}`}
                      id={`chart${index + 1}`}
                      key={index}>
                      <Chart
                        key={index}
                        mobile={mobile}
                        serie1={index + 1 === 6 ? [] : medidasGraficasProcesadas[baseIndex]}
                        serie2={index + 1 === 6 ? [] : medidasGraficasProcesadas[baseIndex + 1]}
                        dato1={index + 1 === 6 ? {} : datosInferiores[baseIndex]}
                        dato2={index + 1 === 6 ? {} : datosInferiores[baseIndex + 1]}
                        navigator={false}
                        comments={index + 1 === 6}
                        tooltipEnabled={tooltipEnabled}
                        nuevoDato1={index + 1 === 6 ? '' : datosNuevos[baseIndex]}
                        nuevoDato2={index + 1 === 6 ? '' : datosNuevos[baseIndex + 1]}
                        serieComments={index + 1 === 6 ? comentariosProcesados : []}

                        pos={baseIndex}
                        setModalDownVar={setModalDownVar}
                        setPosInferior={setPosInferior}
                        setShowModalVarDown={setShowModalVarDown}
                        deleteVarMatriz={deleteVarMatriz}

                        colorSerie1={color1}
                        colorSerie2={color2}
                        //setColorSerie1={setColorSerie1}
                        //setColorSerie2={setColorSerie2}
                        sinColor={sinColor}
                        rig={rig}

                        usaDefault={usaDefault}
                        ejeAutomatico={ejeAutomatico}
                        funcionActualizarVarsDown={funcionActualizarVarsDown}

                        user={user}
                        uid={uid}

                        horizontal={horizontal}
                        showComments={showComentarios}

                        colors={colors}
                      />
                    </div>
                  );
                })}

              </div>

            </div >

            : <div className="container-fluid d-flex flex-column  text-center" id="container"
              style={{ backgroundColor: colors.colorBg }}>
              <div className="row h-100 w-100" style={{ backgroundColor: colors.colorBg }}>

                <div className={`col-sm-1 p-0`}>
                  <Chart
                    mobile={mobile}
                    serie1={medidasGraficasProcesadas[0]}
                    serie2={[]}
                    d1={{}}
                    d2={{}}
                    navigator={true}
                    comments={false}
                    nuevoDato1={datosNuevos[0]}
                    nuevoDato2={{}}
                    tooltipEnabled={false}
                    user={user}
                    uid={uid}

                    horizontal={horizontal}
                    showComments={showComentarios}

                    colors={colors}
                  />
                </div>

                <div className={`col-sm-11 p-0`}>
                  <div className="row justify-content-between">
                    <>
                      {showDataLeft && (
                        <GrillaIzq
                          r={rig}
                          variables={variables}
                          mobile={mobile}
                          tiempoIntervalo={tiempoIntervalo}
                          dataHeredada={allLiveData}
                          user={user}
                          uid={uid}
                          colors={colors}
                        />
                      )}

                      {Array.from({ length: 5 }).map((_, index) => {
                        const baseIndex = index * 2;
                        const color1 = Object.keys(colors).length > 0 ? colors[`grColor${index + 1}_1`] : 'black'
                        const color2 = Object.keys(colors).length > 0 ? colors[`grColor${index + 1}_2`] : 'blue'
                        return (
                          <Chart
                            key={index}
                            mobile={mobile}
                            serie1={medidasGraficasProcesadas[baseIndex]}
                            serie2={medidasGraficasProcesadas[baseIndex + 1]}
                            dato1={datosInferiores[baseIndex]}
                            dato2={datosInferiores[baseIndex + 1]}
                            navigator={false}
                            comments={false}
                            nuevoDato1={datosNuevos[baseIndex]}
                            nuevoDato2={datosNuevos[baseIndex + 1]}
                            tooltipEnabled={tooltipEnabled}

                            pos={baseIndex}
                            setModalDownVar={setModalDownVar}
                            setPosInferior={setPosInferior}
                            setShowModalVarDown={setShowModalVarDown}
                            deleteVarMatriz={deleteVarMatriz}

                            colorSerie1={color1}
                            colorSerie2={color2}
                            //setColorSerie1={setColorSerie1}
                            //setColorSerie2={setColorSerie2}
                            sinColor={sinColor}
                            rig={rig}

                            ejeAutomatico={ejeAutomatico}
                            usaDefault={usaDefault}
                            funcionActualizarVarsDown={funcionActualizarVarsDown}

                            user={user}
                            uid={uid}

                            horizontal={horizontal}
                            showComments={showComentarios}
                            showDataLeft={showDataLeft}

                            colors={colors}
                          />
                        );
                      })}

                      {(!horizontal && showComentarios) && (
                        <Chart
                          mobile={mobile}
                          serie1={[]}
                          serie2={[]}
                          d1={{}}
                          d2={{}}
                          navigator={false}
                          comments={true}
                          tooltipEnabled={tooltipEnabled}
                          serieComments={comentariosProcesados}
                          showDataLeft={showDataLeft}
                          user={user}
                          uid={uid}

                          horizontal={horizontal}
                          showComments={showComentarios}

                          colors={colors}

                        />
                      )}
                    </>

                  </div>

                </div>
              </div>

            </div>
          }

          <ModalVarDown
            posInferior={posInferior}
            variables={variables}
            setSeriesMin={setSeriesMin}
            setSeriesMax={setSeriesMax}
            modalVarDown={modalDownVar}
            setModalVarDown={setModalDownVar}
            show={showModalVarDown}
            handleClose={handleCloseModalVarDown}
            addVarMatriz={addVarMatriz}
            updateVarMatriz={updateVarMatriz}
          />

          <ModalPlantillaNew
            rig={rig} setRig={setRig}
            datosInferiores={datosInferiores}
            setDatosInferiores={setDatosInferiores}
            setIsLoading={setIsLoading}
            tipo={'graph'}
            show={showModalPlantilla}
            handleClose={handleCloseModalPlantilla}
            uid={uid}
            user={user}
          />

        </>
      )
      }
    </>
  );
};

export default LiveGraphs