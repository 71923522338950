import React from 'react'
import { Modal, Button, Form } from 'react-bootstrap';

export const ModalVarIzq = ({
    variables,
    updateVar,
    posIzq,
    posicion,
    setPosicion,
    selectedVar,
    setSelectedVar,
    addVarMatrizIzq,
    updateVarMatrizIzq,
    show,
    handleClose }
) => {

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {updateVar === 0 ? 'Seleccione Variable Izq' : 'Actualizar Variable Izq'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Select
                        aria-label="Select Var"
                        value={posicion}
                        onChange={(e) => {
                            setSelectedVar(variables.find((ld) => parseInt(ld.nb_var_idn) === parseInt(e.target.value)));
                            setPosicion(e.target.value);
                        }}>
                        <option value={0}>Select Var</option>
                        {variables && variables.map((ld) => (
                            <option key={ld.nb_var_idn} value={ld.nb_var_idn}>
                                {ld.vr_var_nom}
                            </option>
                        ))}
                    </Form.Select>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            updateVar === 0
                                ? addVarMatrizIzq(selectedVar, posIzq)
                                : updateVarMatrizIzq(selectedVar, posIzq);
                            handleClose();
                        }}>
                        Save changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
