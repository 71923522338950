import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { toast } from 'react-toastify';
import { deleteBd, getTableBd, getTableByIdBd, insertTableBd } from '../config/functions';
import { LoaderES } from './LoaderES';

const ModalPlantillaNew = (
    { rig,
        setRig,
        datosInferiores,
        setDatosInferiores,
        liveDataM,
        setIsLoading,
        functionN,
        tipo,
        show,
        handleClose,
        user,
        uid
    }
) => {

    const [plantillas, setPlantillas] = useState([])
    const [datosPlantillas, setDatosPlantillas] = useState([])

    const [datosPlantillasIzquierda, setDatosPlantillasIzquierda] = useState([])
    const [isLoadingP, setIsLoadingP] = useState(false)

    useEffect(() => {
        const getPlantillas = async () => {
            try {
                const pl = await getTableBd('getPlantillas')
                setPlantillas(pl);
            } catch (error) {
                toast.warning('bd don\'t work')
            }
        }
        getPlantillas()
    }, []);

    useEffect(() => { setIsLoading(isLoadingP) }, [isLoadingP])

    //-------------------------------------------------------------------------

    const getDatosPlantilla = async (plantillaIdn) => {
        try {
            const dpl = await getTableByIdBd(plantillaIdn, tipo === 'graph' ? 'getdataplantillagraph' : 'getdataplantillald')
            setDatosPlantillas(dpl);
            if (tipo === 'graph') getDatosPlantillaIzq(plantillaIdn)
        } catch (error) {
            toast.warning('bd don\'t work');
        }
    }

    const saveDefault = async () => {
        const tldroUser = rig + ':' + uid
        const tldroUname = rig + ':' + user

        if (window.confirm('Se asignarán los valores de la plantilla seleccionada, está seguro de continuar?')) {
            setIsLoadingP(true)
            try {
                if (tipo === 'graph') {
                    setRig(0)
                    await deleteBd(tldroUname, 'deletedatamatrizgraphusuario')
                    await deleteBd(tldroUser, 'deletedatamatrizgraphizqusuario')
                    if (datosInferiores.length > 0) setDatosInferiores([])
                    await crearPlantillaCompleta()
                } else {
                    if (liveDataM.length > 0) await deleteBd(tldroUser, 'deletedatamatrizusuario')
                    await crearPlantillaCompleta()
                }
                toast.success('Plantilla Asignada de manera correcta, espere el tiempo de actualización o recargue la pagina para ver los cambios.')
            } catch (error) {
                toast.warning('bd don\'t work');
            } finally {
                handleClose()
                setIsLoadingP(false)
            }
        }
    }

    const crearPlantillaCompleta = async () => {

        try {
            datosPlantillas.map(async (dp) => {
                const fecha = new Date();
                let dataSend = {}
                if (tipo === 'graph') {
                    dataSend = {
                        nb_tldro_idn: parseInt(rig),
                        nb_var_idn: parseInt(dp.nb_var_idn),
                        vr_adt_usureg: user,
                        dt_adt_fchreg: fecha.toISOString(),
                        nb_tldvrb_ord: dp.nb_grafica_orden,
                        nb_tldvrb_min: dp.nb_grafica_minimo,
                        nb_tldvrb_max: dp.nb_grafica_maximo
                    }
                } else {
                    dataSend = {
                        nb_tldro_idn: parseInt(rig, 10),
                        nb_var_idn: parseInt(dp.nb_var_idn, 10),
                        nb_usurio_idn: uid,
                        nb_matr_fila: 0,
                        nb_matr_colum: parseInt(dp.nb_datos_orden, 10),
                        vr_adt_usureg: user,
                        dt_adt_fchreg: fecha.toISOString(),
                    };
                }
                await insertTableBd(tipo === 'graph' ? 'createvarmatrizgraph' : 'createvarmatriz', dataSend);

            });

            if (tipo === 'graph') {
                await crearPlantillaCompletaIzq()
            } else {
                await functionN(rig)
            }

        } catch (error) {
            toast.warning('bd don\'t work');
        }
    }

    const crearPlantillaCompletaIzq = async () => {
        try {
            setIsLoading(true)
            datosPlantillasIzquierda.map(async (dp) => {
                const fecha = new Date()
                const dataSendIzq = {
                    nb_tldro_idn: parseInt(rig),
                    nb_var_idn: parseInt(dp.nb_var_idn),
                    nb_usurio_idn: parseInt(uid),
                    nb_datos_grafica_orden: parseInt(dp.nb_datos_orden),
                    vr_adt_usureg: user,
                    dt_adt_fchreg: fecha.toISOString()
                }
                await insertTableBd('createvarmatrizizq', dataSendIzq);
                setRig(rig)
            });
        } catch (error) {
            toast.warning('bd don\'t work');
        } finally {
            setIsLoading(false)
        }
    }

    const getDatosPlantillaIzq = async (plantillaIdn) => {
        try {
            const data = await getTableByIdBd(plantillaIdn, 'getdataplantillagraphIzq')
            if (data.length > 0) setDatosPlantillasIzquierda(data);
        } catch (error) {
            toast.warning('bd don\'t work');
        }

    }

    //------------------------------------------------------------

    return (
        <>
            {isLoadingP
                ? <LoaderES />
                : <Modal show={show} onHide={handleClose} id='plantModal'>
                    <Modal.Header closeButton>
                        <Modal.Title>Aplicación de plantillas por defecto</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId="exampleForm.SelectCustom">
                                <Form.Label>Seleccionar Plantilla</Form.Label>
                                <Form.Control as="select" custom defaultValue={0}
                                    onChange={(e) => getDatosPlantilla(e.target.value)}>
                                    <option value="0" disabled>Default Selection</option>
                                    {plantillas.map(p => (
                                        <option
                                            key={p.nb_plantilla_idn}
                                            value={p.nb_plantilla_idn}>
                                            {p.vr_plantilla_nombre}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary"
                            onClick={handleClose}>
                            Cerrar
                        </Button>
                        <Button variant="primary"
                            onClick={() => saveDefault()}>
                            Guardar Cambios
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
        </>
    )
}

export default ModalPlantillaNew