import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { Modal } from 'react-bootstrap';
import ModalVarSelector from '../../ModalVarSelector';
import { ModalVarDown } from '../../ModalVarDown';
import { deleteBd, getTableBd, getTableByIdBd, insertTableBd, updateTableBd } from '../../../config/functions';

export const Plantillas = ({ variables, mobile, user, colors }) => {

    //-------------------------------------------------------------------------

    const [plantillas, setPlantillas] = useState([])
    const [plantilla, setPlantilla] = useState('')
    const [datosPlantillas, setDatosPlantillas] = useState([])
    const [tipo, setTipo] = useState('')

    const [openModalNuevo, setOpenModalNuevo] = useState(false)
    const [nombreModal, setNombreModal] = useState('')
    const [descripcionModal, setDescripcionModal] = useState('')
    const [editarPlantilla, setEditarPlantilla] = useState(false)
    const [clickEditar, setClickEditar] = useState(0)

    const [showModalVar, setShowModalVar] = useState(false)
    const handleCloseModalVar = () => setShowModalVar(false)

    const [showModalVarDown, setShowModalVarDown] = useState(false)
    const handleCloseModalVarDown = () => setShowModalVarDown(false)
    const [modalDownVar, setModalDownVar] = useState({})
    const [seriesMin, setSeriesMin] = useState(0)
    const [seriesMax, setSeriesMax] = useState(100)

    const [seleccionVar, setSeleccionVar] = useState({})

    const [updateVar, setUpdateVar] = useState(0)
    const [dataUpdateVar, setDataUpdateVar] = useState({})

    const [plantillaDefault, setPlantillaDefault] = useState(0)

    //-------------------------------------------------------------------------

    const getPlantillas = async () => {
        try {
            const pl = await getTableBd('getPlantillas')
            setPlantillas(pl);
        } catch (error) {
            toast.warning('bd don\'t work')
        }
    }

    useEffect(() => { getPlantillas() }, []);

    const getDatosPlantilla = async (plantillaIdn) => {
        try {
            let puntero = ''
            switch (tipo) {
                case 'ld':
                    puntero = 'getdataplantillald'
                    break;
                case 'gr':
                    puntero = 'getdataplantillagraph'
                    break;
                case 'grLd':
                    puntero = 'getdataplantillagraphIzq'
                    break;
                default:
                    break;
            }
            const data = await getTableByIdBd(plantillaIdn, puntero)
            let dVariables = []

            //console.log(data);

            data.forEach(d => {
                const datosVariable = variables.find(v => v.nb_var_idn === d.nb_var_idn)
                const tempo = { ...d, ...datosVariable }
                dVariables.push(tempo)
            })

            if (tipo === 'ld') {
                const ordenado = Array(30).fill('');
                dVariables.forEach((d, index) => {
                    if (d.nb_datos_orden >= 0 && d.nb_datos_orden < 30) {
                        ordenado[d.nb_datos_orden] = d;
                    }
                });
                dVariables = ordenado;
            }

            if (tipo === 'gr') {
                const ordenado = Array(10).fill('');
                dVariables.forEach(d => {
                    if (d.nb_grafica_orden >= 0 && d.nb_grafica_orden < 10) {
                        ordenado[d.nb_grafica_orden] = d;
                    }
                });
                dVariables = ordenado;
                //console.log(ordenado);
            }

            if (tipo === 'grLd') {
                const ordenado = Array(6).fill('');
                dVariables.forEach(d => {
                    if (d.nb_datos_orden >= 0 && d.nb_datos_orden < 6) {
                        ordenado[d.nb_datos_orden] = d;
                    }
                });
                dVariables = ordenado;
            }

            setDatosPlantillas(dVariables);

        } catch (error) {
            toast.warning('bd don\'t work');
        }
    }

    //-------------------------------------------------------------------------

    useEffect(() => {
        if (Object.keys(seleccionVar).length === 0) return
        const tempo = { ...dataUpdateVar, nb_var_idn: parseInt(seleccionVar.nb_var_idn) }
        if (updateVar === 0) guardarDatoPlantilla(tempo)
        if (updateVar === 1) updateDatoPlantilla(tempo)
        handleCloseModalVar()
    }, [seleccionVar])

    const cardLd = (ld, position) => {

        const abrirModalVar = (update) => {
            const fecha = new Date()
            setUpdateVar(update)

            setDataUpdateVar({
                ...ld,
                nb_plantilla_idn: parseInt(plantilla),
                nb_var_idn: parseInt(seleccionVar?.nb_var_idn),
                nb_datos_orden: parseInt(position),
                vr_adt_usureg: 'Coordinador',
                dt_adt_fchreg: fecha.toISOString(),
            })

            setShowModalVar(true)
        }

        return (
            <>
                {ld !== '' ?
                    <div className=" col-6 col-sm-4 col-md-2 col-xl-2 mb-2"
                        key={position + 'ld'}>
                        <div className="card pe-0 ">
                            <div className="card-body text-center p-0">
                                <div className="row">
                                    <div className="col-2 pe-0">
                                        <span >{parseInt(ld.nb_datos_orden) + 1}</span>
                                    </div>
                                    <div className="col-8">
                                        <h6 className="card-title update-var mb-0"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="set Var"
                                            onClick={() => abrirModalVar(1)}>
                                            {ld.vr_var_nom}
                                        </h6>
                                    </div>
                                    <div className="col-2 ps-0">
                                        <i className="btn fa fa-times" onClick={() => eliminarDatoPlantilla(ld.nb_plantilla_datos_idn)}></i>
                                    </div>
                                </div>
                                <h3 className={"card-text mb-0 alarm-var"} > {`${ld.vr_var_umd === 'no/yes' ? 'YES' : 99.99}`} </h3>
                                <p className='mb-1'>{ld.vr_var_umd}</p>
                            </div>
                        </div>
                    </div>
                    : <div className=" col-6 col-sm-4 col-md-2 col-xl-2 mb-2"
                        key={position + 'ld'}>
                        <div className="card pe-0 ">
                            <div className="card-body text-center p-0">
                                <div className="row mt-1">
                                    <h6 className="card-title">Seleccionar</h6>
                                </div>
                                <h3 className="card-text">
                                    <button className='btn btn-outline-danger' onClick={() => abrirModalVar(0)}>
                                        <i className="fa-solid fa-plus"></i>
                                    </button>
                                </h3>
                                <p></p>
                            </div>
                        </div>
                    </div >
                }
            </>
        )
    }

    const cardGr = (gr, serie, position) => {
        const color = serie === 0 ? 'black' : 'blue'

        const abrirModalVar = (update) => {

            setModalDownVar({
                ...gr,
                nb_tldvrb_min: parseInt(gr.nb_grafica_minimo),
                nb_tldvrb_max: parseInt(gr.nb_grafica_maximo),
            })

            setSeriesMax(parseInt(gr.nb_grafica_maximo))
            setSeriesMin(parseInt(gr.nb_grafica_minimo))

            const fecha = new Date()
            setUpdateVar(update)
            setDataUpdateVar({
                ...gr,
                nb_plantilla_idn: parseInt(plantilla),
                nb_var_idn: parseInt(seleccionVar?.nb_var_idn),
                nb_grafica_orden: parseInt(position),
                nb_grafica_maximo: parseInt(seriesMax),
                nb_grafica_minimo: parseInt(seriesMin),
                vr_adt_usureg: 'Coordinador',
                dt_adt_fchreg: fecha.toISOString(),
                nb_plantilla_datos_idn: parseInt(gr.nb_plantilla_datos_idn),
                nb_plantilla_grafica_idn: parseInt(gr.nb_plantilla_grafica_idn),
            })

            setShowModalVarDown(true)
        }

        return (
            <div className="card" style={{ minHeight: '100px' }} key={'gr' + position}>
                <div className="card-body text-center p-0 m-0 ">
                    {(gr && gr !== '') ?
                        <>
                            <div className="row text-center">
                                <div className="col-2 pe-0">
                                    <i className="fa-solid fa-arrow-trend-up fa-rotate-90 fa-xs" id="btn-color-series1"
                                        style={{ color: color }}
                                    />
                                </div>
                                <div className="col-8">
                                    <h6 className="card-title m-0 btn"
                                        style={{ color: color }}
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="set Var"
                                        onClick={() => abrirModalVar(1)}>
                                        {gr.vr_var_nom}
                                    </h6>
                                </div>
                                <div className="col-2 ps-0">
                                    <span style={{ cursor: 'pointer' }}
                                        onClick={() => eliminarDatoPlantilla(gr.nb_plantilla_grafica_idn)}>
                                        <i className="fa fa-times fa-xs btn"
                                            style={{ color: color }} >
                                        </i>
                                    </span>
                                </div>
                            </div>
                            <h4 className={"card-text m-0"}
                                data-toggle="tooltip"
                                data-placement="top"
                                style={{ fontSize: mobile ? '15px' : 'auto' }}
                            >
                                99.99
                            </h4>
                            <div className="row">
                                <div className="col-3 align-self-center">
                                    <p className="m-0"
                                        style={{ fontSize: mobile ? '8px' : "10px", color: color }}>
                                        {gr.nb_grafica_minimo}
                                    </p>
                                </div>
                                <div className="col-6">
                                    <p className="m-0" style={{ fontSize: mobile ? '12px' : 'auto', color: color }}>
                                        {gr.vr_var_umd}
                                    </p>
                                </div>
                                <div className="col-3 align-self-center">
                                    <p className="m-0"
                                        style={{ fontSize: mobile ? '8px' : "10px", color: color }}>
                                        {gr.nb_grafica_maximo}
                                    </p>
                                </div>
                            </div>
                        </>
                        : <>
                            <h6 className="card-title"
                                style={{ fontSize: mobile ? '12px' : 'auto' }}>
                                Seleccionar
                            </h6>
                            <h3 className={`card-text ${mobile ? 'mb-0' : ''}`} >
                                <button className={`btn btn-outline-danger fa-solid fa-plus ${mobile ? 'btn-sm' : ''}`}
                                    onClick={() => abrirModalVar(0)} />
                            </h3>
                            <p></p>
                        </>
                    }
                </div>
            </div>
        )
    }

    const cardGrLd = (grLd, position) => {

        const abrirModalVar = (update) => {
            const fecha = new Date()
            setUpdateVar(update)

            setDataUpdateVar({
                ...grLd,
                nb_plantilla_idn: parseInt(plantilla),
                nb_var_idn: parseInt(seleccionVar?.nb_var_idn),
                nb_datos_orden: parseInt(position),
                vr_adt_usureg: 'variable-grafica',
                dt_adt_fchreg: fecha.toISOString(),
            })

            setShowModalVar(true)
        }

        return (
            <div className="row " style={{ flexGrow: 1 }} key={'grld' + position}>
                <div className="card h-100 d-flex flex-column justify-content-center" >
                    <div className="card-body text-center p-0 m-0 d-flex flex-column justify-content-center">
                        {grLd !== ''
                            ? <>
                                <div className="row" >
                                    <div className="col-1 p-0 m-0"></div>
                                    <div className="col-10">
                                        <h6 className="card-title update-var m-0"
                                            style={{ fontSize: '13px' }}
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="set Var"
                                            onClick={() => abrirModalVar(1)} >
                                            {grLd.vr_var_nom}
                                        </h6>
                                    </div>

                                    <div className="col-1 p-0 m-0">
                                        <span style={{ cursor: 'pointer' }} onClick={() => eliminarDatoPlantilla(grLd.nb_plantilla_datos_idn)}>
                                            <i className="fa fa-times fa-xs" />
                                        </span>
                                    </div>

                                </div>

                                <h4 className={"card-text m-0 alarm-var"}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="set Alarm">
                                    99.99
                                </h4>

                                <div className="row m-0 p-0" style={{ fontSize: '13px' }}>
                                    <p className="m-0">{grLd.vr_var_umd}</p>
                                </div>
                            </>
                            : <>
                                <h6 className="card-title">Seleccionar</h6>
                                <h3 className="card-text">
                                    <button className='btn btn-outline-danger' onClick={() => abrirModalVar(0)}>
                                        <i className="fa-solid fa-plus" />
                                    </button>
                                </h3>
                                <p></p>
                            </>
                        }
                    </div>
                </div>
            </div >
        )
    }

    //-------------------------------------------------------------------------

    useEffect(() => {

        const pDefault = plantillas.find(p => parseInt(p.nb_plantilla_idn) === parseInt(plantilla))
        if (pDefault) setPlantillaDefault(parseInt(pDefault.plantilla_default))

        if (plantilla !== '' && tipo !== '') {
            getDatosPlantilla(plantilla)
        } else {
            setDatosPlantillas([])
        }
    }, [tipo, plantilla])

    useEffect(() => {
        if (editarPlantilla) {
            const temporal = plantillas.find(p => p.nb_plantilla_idn === plantilla)
            setNombreModal(temporal.vr_plantilla_nombre)
            setDescripcionModal(temporal.vr_plantilla_descripcion || '')
        } else {
            setNombreModal('')
            setDescripcionModal('')
        }
    }, [editarPlantilla, clickEditar])

    //-------------------------------------------------------------------------

    const guardarNuevaPlantilla = async () => {

        const fecha = new Date()
        const existe = plantillas.find(p => p.vr_plantilla_nombre.toUpperCase() === nombreModal.toUpperCase())

        if (existe) {
            toast.warning('El nombre de la plantilla ya existe')
            return
        }

        const data = {
            vr_plantilla_nombre: nombreModal.toUpperCase(),
            vr_plantilla_descripcion: descripcionModal,
            vr_adt_usureg: user,
            dt_adt_fchreg: fecha.toISOString(),
        }

        try {
            await insertTableBd('createplantilla', data)
            toast.success('Plantilla creada con exito')
            setOpenModalNuevo(false)
            setNombreModal('')
            setDescripcionModal('')
            const pl = await getTableBd('getPlantillas')
            setPlantillas(pl)
        } catch (e) {
            toast.warning('bd don\'t work')
        }

    }

    const actualizarPlantilla = async () => {
        const data = {
            nb_plantilla_idn: parseInt(plantilla),
            vr_plantilla_descripcion: descripcionModal,
        }
        try {
            await updateTableBd('updateplantilla', data)
            toast.success('Plantilla actualizada con exito')
            setOpenModalNuevo(false)
            setNombreModal('')
            setDescripcionModal('')
            const pl = await getTableBd('getPlantillas')
            setPlantillas(pl)
        } catch (e) {
            toast.warning('bd don\'t work')
        }
    }

    const eliminarPlantilla = async () => {

        const plantillaName = plantillas.find(p => p.nb_plantilla_idn === plantilla).vr_plantilla_nombre

        toast(
            <div className='text-center'>
                <div className="row">
                    <h6>¿Estás seguro de eliminar la plantilla "{plantillaName.toUpperCase()}"?</h6>
                </div>
                <div className="button-group">
                    <button className='btn btn-primary me-2'
                        onClick={async () => {
                            try {
                                await deleteBd(plantilla, 'deletePlantilla')
                                const pl = await getTableBd('getPlantillas')
                                setPlantillas(pl)
                                setPlantilla('')
                                setDatosPlantillas([])
                            }
                            catch (e) {
                                toast.warning('bd don\'t work')
                            }
                            toast.dismiss();
                        }}>Sí</button>
                    <button className='btn btn-warning' onClick={() => toast.dismiss()}>No</button>
                </div>
            </div>,
            { position: toast.POSITION.TOP_CENTER }
        );

    }

    const handleEliminarPlantilla = async () => {
        try {
            await eliminarPlantilla();
            toast.success('Plantilla eliminada con éxito');
        } catch (error) {
            toast.warning('bd don\'t work');
        }
    };

    //-------------------------------------------------------------------------

    const guardarDatoPlantilla = async (data) => {
        const tabla = (tipo === 'ld' || tipo === 'grLd') ? 'createdatoplantillald' : 'createdatoplantillagraph'
        const dataEnviar = {
            ...data,
            nb_plantilla_grafica_idn: parseInt(data.nb_plantilla_grafica_idn),
            nb_grafica_maximo: parseInt(seriesMax),
            nb_grafica_minimo: parseInt(seriesMin),
        }
        const response = await insertTableBd(tabla, dataEnviar)
        if (response) {
            await getDatosPlantilla(plantilla)
            toast.success('Dato guardado con exito')
        }
    }

    const updateDatoPlantilla = async (data) => {
        const tabla = (tipo === 'ld' || tipo === 'grLd') ? 'updatedatoplantillald' : 'updatedatoplantillagraph'
        const dataEnviar = {
            nb_plantilla_datos_idn: parseInt(data.nb_plantilla_datos_idn),
            nb_plantilla_grafica_idn: parseInt(data.nb_plantilla_grafica_idn),
            nb_var_idn: parseInt(data.nb_var_idn),
            nb_grafica_maximo: parseInt(seriesMax),
            nb_grafica_minimo: parseInt(seriesMin),
        }
        const response = await updateTableBd(tabla, dataEnviar)
        if (response) {
            await getDatosPlantilla(plantilla)
            toast.success('Dato Actualizado con exito')
        }
    }

    const eliminarDatoPlantilla = async (id) => {
        const tabla = (tipo === 'ld' || tipo === 'grLd') ? 'deletedatoplantillald' : 'deletedatoplantillagraph'
        await deleteBd(id, tabla)
        await getDatosPlantilla(plantilla)
        toast.info('Dato eliminado con exito')
    }

    //-------------------------------------------------------------------------

    const defaultPlantilla = async () => {
        const data = {
            nb_plantilla_idn: parseInt(plantilla),
            plantilla_default: 1
        }
        try {
            const mensaje = 'Esta seguro de marcar esta Plantilla como default'
            if (window.confirm(mensaje)) {
                await updateTableBd('defaultplantilla', data)
                setPlantillaDefault(1)
                await getPlantillas()
                toast.success('Plantilla actualizada con exito')
            }
        } catch (e) {
            toast.warning('bd don\'t work')
        }
    }

    return (
        <>
            <div className="container">
                <div className="row justify-content-center ">

                    {/* selector de tipo de plantilla */}

                    <div className="col-auto align-self-center mb-2">
                        <button
                            className='btn btn-primary fa-solid fa-plus'
                            title='Nueva Plantilla'
                            onClick={() => {
                                setEditarPlantilla(false)
                                setOpenModalNuevo(true)
                            }}
                        />
                    </div>

                    <div className="col-auto align-self-center mb-2">
                        <button
                            className='btn btn-info fa-solid fa-pencil'
                            title='Editar Plantilla'
                            disabled={plantilla === ''}
                            onClick={() => {
                                setEditarPlantilla(true)
                                setClickEditar(clickEditar + 1)
                                setOpenModalNuevo(true)
                            }}
                        />
                    </div>

                    <div className="col-auto align-self-center mb-2">
                        <button
                            className='btn btn-danger fa-solid fa-trash-alt'
                            title='Eliminar Plantilla'
                            disabled={plantilla === ''}
                            onClick={() => handleEliminarPlantilla()}
                        />
                    </div>

                    {/* selector de plantillas */}
                    <div className="col-auto mb-2">
                        <InputGroup>
                            <InputGroup.Text id="basic-addon1">Seleccionar Plantilla</InputGroup.Text>
                            <Form.Control
                                as="select"
                                value={plantilla}
                                onChange={(e) => setPlantilla(e.target.value)}>
                                <option value="" disabled>Seleccione</option>
                                {plantillas.map((p, index) => (
                                    <option
                                        key={index + 'selPlantilla'}
                                        value={p.nb_plantilla_idn}>
                                        {`${p.vr_plantilla_nombre}  ${p.vr_plantilla_descripcion ? '- (' + p.vr_plantilla_descripcion + ')' : ''}`}
                                    </option>
                                ))}
                            </Form.Control>
                        </InputGroup>
                    </div>

                    <div className="col-12 col-sm-auto mb-2">
                        <InputGroup>
                            <InputGroup.Text id="basic-addon1">Tipo de plantilla</InputGroup.Text>
                            <Form.Control
                                as="select"
                                defaultValue={''}
                                disabled={plantilla === ''}
                                onChange={(e) => setTipo(e.target.value)}>
                                <option value="" disabled>Seleccione Tipo</option>
                                <option value="ld">LiveData</option>
                                <option value="gr">Graphs</option>
                                <option value="grLd">LeftData</option>
                            </Form.Control>
                        </InputGroup>
                    </div>

                    <div className="col-12 col-sm-auto mb-2">
                        <div className="form-check form-check-inline">
                            <input className="form-check-input"
                                type="checkbox"
                                id="defaultPlantilla"

                                disabled={plantilla === ''}
                                checked={plantillaDefault === 1}
                                onChange={(e) => {
                                    if (e.target.checked) defaultPlantilla();
                                    else toast.warning('No se puede desmarcar la plantilla por defecto, debe seleccionar otra plantilla como default y las demas se desactivaran')
                                }}
                            />
                            <label className="form-check-label" htmlFor="defaultPlantilla"
                                style={{ color: colors.colorFont || '#000000' }}>Default</label>
                        </div>
                    </div>

                </div>
            </div>

            <div className="row justify-content-center mt-3">
                {/* LiveData */}
                {(plantilla !== '' && tipo === 'ld') &&
                    datosPlantillas.map((ld, index) => (cardLd(ld, index))
                    )}

                {/* graphs */}
                {((plantilla !== '' && tipo === 'gr') &&
                    datosPlantillas.length > 0) && Array.from({ length: 5 }, (_, i) => i * 2).map(i => (
                        <div className={`${mobile ? 'col-6 m-2' : 'col-2'}`} key={'dp' + i}>
                            {cardGr(datosPlantillas[i], 0, i)}
                            {cardGr(datosPlantillas[i + 1], 1, i + 1)}
                        </div>
                    ))}

                {/* left data */}
                {(plantilla !== '' && tipo === 'grLd') && (
                    <div className={`${mobile ? 'col-8' : 'col-2'}`}>
                        {datosPlantillas.map((grLd, index) => (cardGrLd(grLd, index)))}
                    </div>
                )}
            </div>

            {/* modal usando react-bootsrap de nueva plantilla con los siguientes campos: Nombre de la plantilla y descripcion */}
            <Modal
                show={openModalNuevo}
                onHide={() => setOpenModalNuevo(false)}>
                <Modal.Header closeButton>
                    <Modal.Title> {editarPlantilla ? 'Actualizar ' : 'Nueva '}Plantilla</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <InputGroup className="mb-3" controlId="formBasicEmail">
                            <InputGroup.Text id="basic-addon1">Nombre de la Plantilla</InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Nombre de la Plantilla"
                                value={nombreModal}
                                disabled={editarPlantilla}
                                onChange={(e) => setNombreModal(e.target.value)} />
                        </InputGroup>
                        <InputGroup className="mb-3" controlId="formBasicPassword">
                            <InputGroup.Text id="basic-addon1">Descripcion</InputGroup.Text>
                            <Form.Control
                                as="textarea"
                                placeholder="Descripcion de la Plantilla"
                                value={descripcionModal}
                                disabled={nombreModal === '' && !editarPlantilla}
                                onChange={(e) => setDescripcionModal(e.target.value)} />
                        </InputGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className='btn btn-primary'
                        disabled={nombreModal === ''}
                        onClick={() => editarPlantilla ? actualizarPlantilla() : guardarNuevaPlantilla()}>
                        {editarPlantilla ? 'Actualizar' : 'Guardar'}
                    </button>
                </Modal.Footer>
            </Modal>


            <ModalVarSelector
                variables={variables}
                updateVar={updateVar}
                addVarMatriz={setSeleccionVar}
                updateVarMatriz={setSeleccionVar}
                show={showModalVar}
                handleClose={handleCloseModalVar}
            />

            <ModalVarDown
                /* posInferior={posInferior}*/
                setSeriesMin={setSeriesMin}
                setSeriesMax={setSeriesMax}
                variables={variables}
                modalVarDown={modalDownVar}
                setModalVarDown={setModalDownVar}
                show={showModalVarDown}
                handleClose={handleCloseModalVarDown}
                addVarMatriz={setSeleccionVar}
                updateVarMatriz={setSeleccionVar}
            />

        </>
    )
}