import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { Modal, Button } from 'react-bootstrap';
import { insertTableBd, updateTableBd } from '../../../config/functions';

export const ModalUserEdit = (
    {
        usuarios,
        usuarioAll,
        setIsLoading,
        getUsers,
        show,
        handleClose,
        modal,
        user
    }
) => {

    const idUser = usuarioAll.nb_usurio_idn

    const [usuario, setUsuario] = useState('')
    const [nombre1, setNombre1] = useState('')
    const [nombre2, setNombre2] = useState('')
    const [apellido1, setApellido1] = useState('')
    const [apellido2, setApellido2] = useState('')
    const [cel, setCel] = useState('')
    const [correo, setCorreo] = useState('')
    const [estado, setEstado] = useState('a')
    const [tipo, setTipo] = useState(2)

    useEffect(() => {
        if (show && modal !== 'Nuevo') {
            setUsuario(usuarioAll.vr_usurio_usu)
            setNombre1(usuarioAll.vr_usurio_nom1)
            setNombre2(usuarioAll.vr_usurio_nom2)
            setApellido1(usuarioAll.vr_usurio_ape1)
            setApellido2(usuarioAll.vr_usurio_ape2)
            setCel(usuarioAll.vr_usurio_telefn)
            setCorreo(usuarioAll.vr_usurio_cor)
            setEstado(usuarioAll.vr_usurio_est)
            setTipo(usuarioAll.nb_rol_idn)
        }
    }, [show])

    const createUser = async () => {
        const existe = usuarios.find(u => u.vr_usurio_usu === usuario)
        if (existe && existe.vr_usurio_usu === usuario) {
            toast.warning('Ya existe un usuario con ese nombre')
        } else {
            try {
                setIsLoading(true)
                const fecha = new Date()
                const datosEnviar = {
                    vr_usurio_usu: usuario,
                    vr_usurio_nom1: nombre1,
                    vr_usurio_nom2: nombre2,
                    vr_usurio_ape1: apellido1,
                    vr_usurio_ape2: apellido2,
                    nb_rol_idn: tipo,
                    vr_usurio_cor: correo,
                    vr_usurio_est: estado,
                    vr_adt_usureg: user,
                    dt_adt_fchreg: fecha.toISOString(),
                    nb_usurio_cmbcla: 1,
                    vr_usurio_celulr: cel,
                    vr_usurio_telefn: '',
                    vr_usurio_cla: '123'
                }
                if (usuario !== '') {
                    //console.log(datosEnviar);
                    await insertTableBd('createuser2', datosEnviar)
                    handleClose()
                    toast.success('Usuario guardado con éxito')
                    getUsers()
                } else {
                    toast.warning('Debe diligenciar Usuario, tipo y estado')
                }
            } catch (error) {
                toast.warning('bd don\'t work');
            } finally {
                setIsLoading(false)
            }
        }
    }

    const updateUser = async () => {
        if (usuario !== '') {
            if (window.confirm('Esta seguro de continuar con la edición?')) {
                setIsLoading(true)
                try {
                    const fecha = new Date()
                    const dataSend = {
                        nb_usurio_idn: idUser,
                        vr_usurio_usu: usuario,
                        nb_rol_idn: tipo,
                        vr_usurio_nom1: nombre1,
                        vr_usurio_nom2: nombre2,
                        vr_usurio_ape1: apellido1,
                        vr_usurio_ape2: apellido2,
                        vr_usurio_telefn: cel,
                        vr_usurio_cor: correo,
                        vr_usurio_est: estado,
                        vr_adt_usureg: user,
                        dt_adt_fchreg: fecha.toISOString()
                    }
                    const data = await updateTableBd('updateUser2', dataSend)
                    toast.success(data);
                    handleClose()
                    await getUsers();

                } catch (error) {
                    toast.warning('bd don\'t work');
                } finally {
                    setIsLoading(false)
                }
            }
        } else {
            toast.warning('Debe diligenciar Todos los campos')
        }

    }


    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {modal === 'Nuevo' ? 'Creación ' : 'Edición '} de usuario: {idUser === 0 ? '' : idUser}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="row g-3 justify-content-center">
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1">Usuario</span>
                            <input type="text"
                                className="form-control"
                                placeholder="Nombre"
                                aria-label="Nombre"
                                value={usuario}
                                disabled={modal !== 'Nuevo'}
                                onChange={(e) => setUsuario(e.target.value)} />
                        </div>
                    </div>

                    <div className="row g-3 justify-content-center">
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1">Tipo</span>
                            <select className="form-select"
                                onChange={(e) => setTipo(e.target.value)}
                                value={tipo}>
                                <option disabled value={0}>Seleccione</option>
                                <option value={1}>Administrador</option>
                                <option value={2}>Usuario</option>
                            </select>
                        </div>
                    </div>

                    <div className="row g-3 justify-content-center">
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1">Primer Nombre</span>
                            <input type="text"
                                className="form-control"
                                placeholder="Nombre 1"
                                aria-label="Nombre 1"
                                value={nombre1}
                                onChange={(e) => setNombre1(e.target.value)} />
                        </div>
                    </div>

                    <div className="row g-3 justify-content-center">
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1">Segundo Nombre</span>
                            <input type="text"
                                className="form-control"
                                placeholder="Nombre 2"
                                aria-label="Nombre 2"
                                value={nombre2}
                                onChange={(e) => setNombre2(e.target.value)} />
                        </div>
                    </div>

                    <div className="row g-3 justify-content-center">
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1">Primer Apellido</span>
                            <input type="text"
                                className="form-control"
                                placeholder="Apellido 1"
                                aria-label="Apellido 1"
                                value={apellido1}
                                onChange={(e) => setApellido1(e.target.value)} />
                        </div>
                    </div>

                    <div className="row g-3 justify-content-center">
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1">Segundo Apellido</span>
                            <input type="text"
                                className="form-control"
                                placeholder="Apellido 2"
                                aria-label="Apellido 2"
                                value={apellido2}
                                onChange={(e) => setApellido2(e.target.value)} />
                        </div>
                    </div>

                    <div className="row g-3 justify-content-center">
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1">Cel</span>
                            <input type="text"
                                className="form-control"
                                placeholder="Cel"
                                aria-label="Cel"
                                value={cel}
                                onChange={(e) => setCel(e.target.value)} />
                        </div>
                    </div>

                    <div className="row g-3 justify-content-center">
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1">e-mail</span>
                            <input type="text"
                                className="form-control"
                                placeholder="e-mail"
                                aria-label="e-mail"
                                value={correo}
                                onChange={(e) => setCorreo(e.target.value)} />
                        </div>
                    </div>

                    <div className="row g-3 justify-content-center">
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1">Estado</span>
                            <select
                                className="form-select"
                                onChange={(e) => setEstado(e.target.value)}
                                value={estado}>
                                <option value='' disabled>Seleccione</option>
                                <option value="a">Activo</option>
                                <option value="i">Inactivo</option>
                            </select>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}> Close </Button>
                    <Button variant="primary" onClick={() => modal === 'Nuevo' ? createUser() : updateUser()}>
                        {modal === 'Nuevo' ? 'create new User' : 'Save changes'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
