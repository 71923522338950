import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import { documentation } from './rutas';

const Help = ({ ruta }) => {

    const [informacion, setInformacion] = useState({})
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const [tab, setTab] = useState('Información')
    const tabs = ['Introducción', 'Navegación', 'Información', 'Notificaciones'];

    const handleShow = () => {
        getInfo()
        setShow(true)
    }

    const getInfo = async () => {
        const data = documentation.filter(item => item.path === ruta)[0]
        setInformacion(data)
    }

    return (
        <>
            <i className='btn btn-sm fa-solid fa-question-circle'
                style={{ color: 'aqua' }}
                onClick={handleShow} />

            <Modal show={show} onHide={handleClose} size="lg" /* fullscreen={true} */>
                <Modal.Header closeButton>
                    <Modal.Title>Información de Ayuda</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {informacion
                        ? <div className='container'>
                            {/* Encabezado */}
                            <h5 className="modal-title">{`${informacion.component}`}</h5>
                            <p className="text-muted small">{`Path: ${informacion.path}`}</p>

                            <div className="row justify-content-center ">
                                {tabs.map((tabName) => (
                                    <div className="col-auto nav nav-tabs" onClick={() => setTab(tabName)} key={tabName}>
                                        <p className={`nav-link ${tab === tabName ? 'active' : ''}`} id={tabName}>
                                            {tabName.charAt(0).toUpperCase() + tabName.slice(1)}
                                        </p>
                                    </div>
                                ))}
                            </div>

                            {tab === 'Introducción' && (
                                <section className="mt-3">
                                    <h6>Introducción</h6>
                                    <p>{informacion.introduccion}</p>
                                </section>
                            )}

                            {tab === 'Navegación' && (
                                <section className="mt-3">
                                    <h6>Navegación</h6>
                                    <p>{informacion.navegacion}</p>
                                </section>
                            )}

                            {tab === 'Información' && (
                                <section className="mt-3">
                                    <h6>Información</h6>
                                    <p>{informacion.informacion}</p>
                                    <div className="table-responsive">
                                        <table className="table table-hover table-sm table-bordered table-striped align-middle">
                                            <thead>
                                                <tr>
                                                    <th>Funcionalidad</th>
                                                    <th>Descripción</th>
                                                    <th>Btn</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ fontSize: '12px' }}>
                                                {informacion?.opciones?.map((op, index) => (
                                                    <tr key={index} className={op.estilo}>
                                                        <td><strong>{op.opcion}</strong></td>
                                                        <td>{op.detalle}</td>
                                                        <td className='text-center'>{op.boton ? <button className={`btn ${op.boton}`} /> : ''}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </section>
                            )}

                            {tab === 'Notificaciones' && (
                                <section className="mt-3">
                                    <h6>Notificaciones</h6>
                                    <div className="alert alert-info" role="alert">
                                        {informacion.notificaciones}
                                    </div>
                                </section>
                            )}

                        </div>
                        : <div className="text-center">
                            <h6 className="pt-3">No existe documentación para este componente, pronto estará disponible!.</h6>
                        </div>
                    }


                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default Help