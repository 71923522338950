import React from 'react';
import { Modal, Table } from 'react-bootstrap';

const ModalVarSelector = ({ variables, updateVar, addVarMatriz, updateVarMatriz, show, handleClose }) => {

    return (
        <>
            <Modal show={show} onHide={handleClose} id='varModal' aria-labelledby='varModalLabel'>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {updateVar === 0 ? 'Seleccione ' : 'Actualizar '} Variable
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Variable</th>
                                <th>Unidad</th>
                            </tr>
                        </thead>
                        <tbody>
                            {variables &&
                                variables.map(ld => (
                                    <tr
                                        key={ld.nb_var_idn}
                                        onClick={() => updateVar === 0 ? addVarMatriz(ld) : updateVarMatriz(ld)}
                                    >
                                        <td className='text-start'>{ld.vr_var_nom}</td>
                                        <td>{ld.vr_var_umd}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ModalVarSelector