import CryptoJS from "crypto-js";

export function comparePassword(inputPassword, storedHash) {
    const inputHash = CryptoJS.SHA256(inputPassword).toString(CryptoJS.enc.Hex);
    return inputHash === storedHash;
}

export function cryptPassword(inputPassword) {
    const inputHash = CryptoJS.SHA256(inputPassword).toString(CryptoJS.enc.Hex);
    return inputHash;
}