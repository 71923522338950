import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsSankey from 'highcharts/modules/sankey';
import HighchartsOrganization from 'highcharts/modules/organization';
import MyRigs from './MyRigs';
import { deleteBdNoId, getTableByIdBd } from '../config/functions';
import { LoaderES } from './LoaderES';
import '../css/home.css';

HighchartsSankey(Highcharts);
HighchartsOrganization(Highcharts);

const Home = ({ broken, collapsed, setRig, setComponente, mobile, user, uid, colors }) => {

    const [taladrosUsuario, setTaladrosUsuario] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [asignaciones, setAsignaciones] = useState(false)

    const chartComponent = useRef(null);
    const [chart, setChart] = useState(null)

    useEffect(() => { getRigs() }, [])

    const getRigs = async () => {

        function verificarDuplicados(arr) {
            const visto = {};
            for (let i = 0; i < arr.length; i++) {
                if (visto[arr[i].nb_tldro_idn]) return true;
                visto[arr[i].nb_tldro_idn] = true;
            }
            return false;
        }

        setIsLoading(true)
        try {
            const data = await getTableByIdBd(uid, 'getrigsusers')
            if (data.length >= 1) {
                const duplicados = verificarDuplicados(data)
                if (duplicados) setAsignaciones(true)
                setTaladrosUsuario(data)
            }
        } catch (error) {
        } finally {
            setIsLoading(false)
        }
    }

    const [chartOptions, setChartOptions] = useState({
        chart: {
            height: 600,
            inverted: true,
            backgroundColor: colors.colorBg,
        },
        title: {
            text: null // Aquí se quita el título del gráfico
        },

        series: [{
            type: 'organization',
            name: 'organization',
            //keys: ['from', 'to'],
            data: [],
            nodes: [],
            levels: [
                {
                    level: 0,
                    color: 'silver',
                    dataLabels: {
                        color: 'black',
                        style: { fontSize: '12px' }

                    },
                },
                {
                    level: 1,
                    dataLabels: {
                        color: 'black',
                        style: { fontSize: '10px' }
                    },
                },
                {
                    level: 2,
                    color: '#980104',
                    dataLabels: {
                        style: {
                            fontSize: '10px'
                        }
                    },
                },
                /* {
                    level: 3,
                    color: '#359154',
                    dataLabels: {
                        style: {
                            fontSize: '10px' 
                        }
                    },
                }, */
            ],
            colorByPoint: false,
            color: '#007ad0',
            dataLabels: {
                color: 'white',
                style: {
                    fontSize: '10px'
                }
            },
            borderColor: 'white',
            nodeWidth: 125,

        }],
    });

    useEffect(() => {
        setChartOptions({
            ...chartOptions, chart: {
                height: 600,
                inverted: true,
                backgroundColor: colors.colorBg,
            }
        })
        /* chart. */
    }, [colors])

    useEffect(() => {
        if (chartComponent.current) setChart(chartComponent.current.chart);
    }, [chartComponent]);

    useEffect(() => {
        const { nodes, links } = transformDataToChartFormat(taladrosUsuario);

        setChartOptions({
            ...chartOptions,
            series: [{
                ...chartOptions.series[0],
                data: links,
                nodes,
                levels: chartOptions.series[0].levels.map(level => ({
                    ...level,
                    dataLabels: {
                        ...level.dataLabels,
                        style: {
                            fontSize: '8px' // Cambia este valor para ajustar el tamaño del texto
                        }
                    },
                }))
            }],
        });
    }, [taladrosUsuario])


    const transformDataToChartFormat = (data) => {
        const nodes = [{
            id: user, //title: user 
        }];
        const links = [];

        const machines = new Map();

        data.forEach((row) => {
            const machineId = row.vr_tldro_nom.split('-')[0];
            const locationId = row.vr_tldro_nom.split('-')[1];
            const locationInfo = `${moment(row.dt_adt_last_fchreg).format('DD-mm-YY h:mm a')}`;//`Inicio: ${row.dt_adt_fchreg}, Fin: ${row.dt_adt_last_fchreg}`;

            if (!machines.has(machineId)) {
                machines.set(machineId, new Set());
                nodes.push({
                    id: machineId,
                    //title: locationId,
                });
                links.push([user, machineId]);
            }

            machines.get(machineId).add(locationId);
            nodes.push({
                id: locationId,
                title: locationInfo,
                //name: locationInfo,
            });
            links.push([machineId, locationId]);
        });

        return { nodes, links };
    };

    //----------------------------

    const eliminarAsignacionesDuplicadas = async () => {
        try {
            await deleteBdNoId('asignacionesduplicadas')
            getRigs()
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => { if (asignaciones) eliminarAsignacionesDuplicadas() }, [asignaciones])

    //---------------------------

    return (
        taladrosUsuario.length === 0 || isLoading
            ? <LoaderES />
            : mobile
                ? <MyRigs
                    broken={broken}
                    collapsed={collapsed}
                    setRig={setRig}
                    setComponente={setComponente}
                    mobile={mobile}
                    user={user}
                    uid={uid} />
                : <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptions}
                    ref={chartComponent} />
    )
};


export default Home