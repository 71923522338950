import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Login } from '../components/Login';

import Menu from '../components/menu/Menu';
import { RutaPrivada } from './RutaPrivada';

export const AppRouter = () => {
    return (
        <>
            <Router basename='/WellCheck'>
                <Switch>
                    <Route exact path="/" component={Login} />

                    <RutaPrivada path="/menu" component={Menu} />
                    <RutaPrivada path="/home" component={Menu} />
                    <RutaPrivada path="/livedata" component={Menu} />
                    <RutaPrivada path="/livegraphs" component={Menu} />
                    <RutaPrivada path="/pits" component={Menu} />
                    <RutaPrivada path="/variables" component={Menu} />
                    <RutaPrivada path="/variablesForm" component={Menu} />
                    <RutaPrivada path="/users" component={Menu} />
                    <RutaPrivada path="/myrigs" component={Menu} />
                    <RutaPrivada path="/modbus" component={Menu} />
                    <RutaPrivada path="/plantillas" component={Menu} />
                    <RutaPrivada path="/rigs" component={Menu} />

                </Switch>
            </Router>
        </>
    )
}