import React from 'react';
import { useHistory } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const LogoutButton = ({ theme }) => {

    const history = useHistory();

    const handleLogout = () => {
        confirmAlert({
            title: 'Confirmar',
            message: '¿Estás seguro que deseas cerrar sesión?',
            buttons: [
                {
                    label: 'Sí',
                    onClick: () => { deleteActiveSesion() }
                },
                { label: 'No' }
            ]
        });
    };

    const deleteActiveSesion = async () => {
        localStorage.removeItem("token");
        history.push("/");
    }

    return <div
        className={`update-var`}
        style={{ color: theme === 'dark' ? 'white' : '' }}
        onClick={handleLogout}>
        LogOut
    </div>
};

export default LogoutButton;