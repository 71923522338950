import { Form, Button, InputGroup } from 'react-bootstrap';

export const FormField = (cId, name, type, value, label, func, dis) => {
    return (
        <div className="col-sm-2">
            <Form.Group controlId={cId} className='mb-3'>
                <InputGroup size='sm'>
                    <InputGroup.Text>{label}</InputGroup.Text>
                    <Form.Control
                        type={type}
                        name={name}
                        disabled={dis}
                        onChange={func}
                        value={value} />
                </InputGroup>
            </Form.Group>
        </div>
    );
}

export const formButton = (func1, conOpen, text) => {
    return (
        <div className="col-sm-2">
            <Button
                size='sm'
                variant="primary"
                type="button"
                disabled
                onClick={() => func1()}>
                {conOpen ? `Close ${text} Connection` : `Open ${text} Connection`}
            </Button>
        </div>
    )
}

export const formSelect = (name, func, dis, value, text, array, nuevo) => {
    return (
        <div className="col-sm-2">
            <Form.Group controlId="rig" className='mb-3'>
                <InputGroup size='sm'>
                    <InputGroup.Text>Rig</InputGroup.Text>
                    <Form.Select
                        name={name}
                        disabled={dis}
                        onChange={func}
                        value={value}
                    >
                        <option value="">{text}</option>
                        {array.map(r => (
                            <option key={r.nb_tldro_idn} value={r.nb_tldro_idn}>{r.vr_tldro_nom}</option>
                        ))}
                        <option value={nuevo[0]}>{nuevo[1]}</option>
                    </Form.Select>
                </InputGroup>
            </Form.Group>
        </div>
    )
}

export const formAsSelect = (name, func, dis, value, text, array) => {
    return (
        <div className="col-sm-2">
            <Form.Group controlId="connectionType" className='mb-3'>
                <InputGroup size='sm'>
                    <InputGroup.Text>{text}</InputGroup.Text>
                    <Form.Control
                        as="select"
                        name={name}
                        onChange={func}
                        disabled={dis}
                        value={value}>
                        {array.map(a => (<option value={a} key={a}>{a.toUpperCase()}</option>))}
                    </Form.Control>
                </InputGroup>
            </Form.Group>
        </div>
    )
}

export const formFieldWits = (id, label, type, options, value, setValue) => {
    return (
        <div className="col-sm-2">
            <Form.Group controlId={id} className='mb-3'>
                <InputGroup size='sm'>
                    <InputGroup.Text>{label}</InputGroup.Text>
                    <Form.Control
                        as={type === 'select' ? 'select' : 'input'}
                        type={type}
                        name={id}
                        onChange={(e) => setValue(e.target.value)}
                        value={value}>
                        {type === 'select' ?
                            options.map(option => (
                                <option key={option} value={option}>{option}</option>
                            ))
                            : null
                        }
                    </Form.Control>
                </InputGroup>
            </Form.Group>
        </div>
    )
}