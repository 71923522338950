import React, { useEffect, useState } from 'react'
import { Modal, Button, Form, Container, Row, Col } from 'react-bootstrap';


export const ModalVarDown = (
    {
        posInferior,
        variables,
        setSeriesMin,
        setSeriesMax,
        modalVarDown,
        setModalVarDown,
        show,
        handleClose,
        addVarMatriz,
        updateVarMatriz
    }
) => {

    const [idn, setIdn] = useState(modalVarDown.nb_var_idn)
    const [updateVar, setUpdateVarD] = useState(modalVarDown.nb_tldvrb_idn)
    const [seriesMin, setSeriesMinD] = useState(modalVarDown.nb_tldvrb_min)
    const [seriesMax, setSeriesMaxD] = useState(modalVarDown.nb_tldvrb_max)

    useEffect(() => {
        setIdn(modalVarDown.nb_var_idn)
        setUpdateVarD(modalVarDown.nb_tldvrb_idn)
        setSeriesMinD(modalVarDown.nb_tldvrb_min)
        setSeriesMaxD(modalVarDown.nb_tldvrb_max)
    }, [modalVarDown])


    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {updateVar === 0 ? 'Seleccione Variable' : 'Actualizar Variable '}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            {/* seriesMin */}
                            <Col xs={3}>
                                <Form.Control
                                    type="number"
                                    placeholder="min"
                                    onChange={(e) => {
                                        setSeriesMin(e.target.value)
                                        setSeriesMinD(e.target.value)
                                    }}
                                    value={seriesMin}
                                />
                            </Col>

                            {/* Variable */}
                            <Col xs={6}>
                                <Form.Select
                                    aria-label="Select Var"
                                    value={idn}
                                    onChange={(e) => {
                                        setModalVarDown(variables.find((ld) => parseInt(ld.nb_var_idn) === parseInt(e.target.value)));
                                        setIdn(e.target.value);
                                    }}>
                                    <option value={0}>Select Var</option>
                                    {variables && variables.map((ld) => (
                                        <option key={ld.nb_var_idn} value={ld.nb_var_idn}>
                                            {ld.vr_var_nom}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Col>

                            {/* seriesMax */}
                            <Col xs={3}>
                                <Form.Control
                                    type="number"
                                    placeholder="max"
                                    onChange={(e) => {
                                        setSeriesMax(e.target.value)
                                        setSeriesMaxD(e.target.value)
                                    }}
                                    value={seriesMax}
                                />
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => {
                        Object.keys(modalVarDown).length > 0 ? addVarMatriz(modalVarDown, posInferior) : updateVarMatriz(modalVarDown, posInferior);
                        handleClose();
                    }}>
                        {Object.keys(modalVarDown).length > 0 ? 'Save changes' : 'Update changes'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
