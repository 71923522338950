import React, { useState } from 'react';
import { formFieldWits } from '../../../config/minis';

const ConnectionWits = () => {

    const [port, setPort] = useState(0)
    const [baudRate, setBaudRate] = useState(0)
    const [dataBits, setDataBits] = useState(0)
    const [parity, setParity] = useState('none')
    const [stopBits, setStopBits] = useState('1')


    return (
        <>
            {formFieldWits('witsCOM', 'COM', 'number', null, port, setPort)}
            {formFieldWits('witsBaudRate', 'Baud Rate', 'number', null, baudRate, setBaudRate)}
            {formFieldWits('witsDataBits', 'Data Bits', 'number', null, dataBits, setDataBits)}
            {formFieldWits('witsParity', 'Parity', 'select', ['none', 'even', 'odd'], parity, setParity)}
            {formFieldWits('witsStopBits', 'Stop Bits', 'select', ['1', '1.5', '2'], stopBits, setStopBits)}
        </>
    )
}

export default ConnectionWits